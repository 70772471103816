import React, { useState } from 'react';
import Gallery from './gallery/Gallery';
import MutationMenu from './creator/MutationMenu';

function MutationServiceMenu() {
    const [selectedService, setSelectedService] = useState("mutation");

    function closeCreatorMenu() {
        setSelectedService("gallery")
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                <div style={{ margin: '0 10px', padding: '10px', cursor: 'pointer', borderBottom: selectedService === 'creator' ? '3px solid blue' : '' }} 
                     onClick={() => { setSelectedService("mutation"); } }>
                    Creator
                </div>
                <div style={{ margin: '0 10px', padding: '10px', cursor: 'pointer', borderBottom: selectedService === 'gallery' ? '3px solid blue' : '' }} 
                     onClick={() => setSelectedService('gallery')}>
                    Gallery
                </div>
            </div>

            {selectedService === "mutation" && <MutationMenu key={new Date().getTime()} onCreatorModeClose={closeCreatorMenu} />}
            {selectedService === "gallery" && <Gallery />}
        </div>
    )
}

export default MutationServiceMenu;