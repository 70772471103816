import React, { useState, useEffect } from 'react';
import { HttpGet } from '../http/HttpClient';
import T from '../language.js'
import '../css/App.css';
import Cookies from 'js-cookie';

function UserPopup({ onClose }) {
    const [inviteLink, setInviteLink] = useState("")
  
    useEffect(() => {
      const getUserInviteCode = async () => {
        try {
            const response = await HttpGet('/user/invite_code')
            const result = response.data.data.result;
            setInviteLink("https://darkforce.ai/?invite_code=" + result)
        } catch (error) {
            console.error('Error fetching user images:', error);
        }
      };
      getUserInviteCode()
    }, []);

    const logout = () => {
      Cookies.remove("X-API-TOKEN");
      Cookies.remove("X-USERNAME");
      localStorage.clear();
      window.location.href = "/";
      // window.location.reload();
    }
  
    return (
      <div className="user-popup">
        <div className="popup-content">
          <h3>Invite and Get Free ⭐⭐⭐</h3>
          <p>{T("Invite Link")}: {inviteLink}</p>
          <p>For each user registered using your invite link:</p>
          <ul>
            <li>You get ⭐10 when they register</li>
            <li>You get ⭐10 for every $1 they pay (if they pay $10, you get ⭐10 ) </li>
          </ul>
          <button onClick={onClose}>Close</button>
          <button onClick={logout}>Logout</button>
        </div>
      </div>
    );
  }
  
  export default UserPopup