import React, { useState, useEffect } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
  } from "react-router-dom";
import Home from "./Home";
import UserInfoSection from './user/UserInfoSection.js'
import UserPopup from './user/UserPopup.js'
import Chat from "./service/chat/chat";
import AiReply from "./service/chat/aireply";
import PaypalPayment from './service/payment/PaypalPayment.js';

import DigitalModelMenu from './service/digitalmodel/DigitalModelMenu.js';
import DigitalModelEditor from './service/digitalmodel/gallery/DigitalModelEditor.js';
import GeneratorServiceMenu from './service/generator/GeneratorServiceMenu.js';
import MutationServiceMenu from './service/generator/MutationServiceMenu.js';
import FaceswapMenu from './service/faceswap/FaceswapMenu.js';
import FaceswapVideoMenu from './service/faceswap/FaceswapVideoMenu.js';
import Feed from './service/feed/Feed.js';
import FeedForDM from './service/feed/FeedForDM.js';

import './css/App.css';
import { isLoggedIn } from "./service/common/util";
import { UpdateUserPoints } from './user/User.js';
import PortraitPhotoCreator from './service/digitalmodel/creator/PortraitPhotoCreator.js';
import DMChat from './service/chat/dmchat.js';

function App() {
    const [key, setKey] = useState(0);
    const [showUserPopup, setShowUserPopup] = useState(false);
    const [showPaymentPopup, setShowPaymentPopup] = useState(false);
    const [showLoginSection, setShowLoginSection] = useState(false);
    const [showRegisterSection, setShowRegisterSection] = useState(false);
    const [inviteCode, setInviteCode] = useState('');

    const startLogin = () => {
        setShowRegisterSection(false);
        setShowLoginSection(true);
    }

    const startRegister = () => {
        setShowRegisterSection(true);
        setShowLoginSection(false);
    }

    const showHomescreen = () => {
        setShowLoginSection(false);
        setShowRegisterSection(false);
        window.location.href = "/";
    }

    const reload = () => {
        setKey(prevKey => prevKey + 1);
        UpdateUserPoints();
    };

    const handleUsernameClick = () => {
        setShowUserPopup(true);
      }
    
    const closeUserPopup = () => {
    setShowUserPopup(false);
    }

    const closePaymentPopup = () => {
    setShowPaymentPopup(false);
    }
        
    useEffect(() => {
    if (inviteCode === "") {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('invite_code');
        if (code) {
            setInviteCode(code);
            setShowRegisterSection(true);
            setShowLoginSection(false);
        }
    }
    
        reload();
    }, [showLoginSection, showRegisterSection]);
    
    return (
        <BrowserRouter>
        <div className="App" key={key}>
            <header className="App-header">
                <h1 onClick={()=>{showHomescreen();reload();}}>Darkforce.AI</h1>
                {isLoggedIn() && (
                    <span className='copyright' onClick={(e)=>{
                    e.preventDefault();
                    window.location="./tutorial/index.html";
                    }}>Tutorial</span>
                )}
                {isLoggedIn() && (
                <UserInfoSection 
                onUsernameClick={handleUsernameClick} 
                onUserPointsClick={()=>{setShowPaymentPopup(!showPaymentPopup)}}/>
                )}
                {!isLoggedIn() && (
                <div className="user-info">
                    <span className="username" onClick={showHomescreen}>HOME</span>
                    <span className="username" onClick={startLogin}>LOGIN</span> | 
                    <span className="username" onClick={startRegister}>REGISTER</span>
                </div>
                )}
            </header>
            <Routes>
                <Route path="/" element={<Home 
                    showLoginSection={showLoginSection} 
                    showRegisterSection={showRegisterSection} 
                    inviteCode={inviteCode}
                    onLogin={()=>{
                        reload()
                    }}
                    />} /> 

                <Route path="/faceswap" element={<FaceswapMenu />} />
                <Route path="/faceswapvideo" element={<FaceswapVideoMenu />} />
                <Route path="/creator" element={<GeneratorServiceMenu />} />
                <Route path="/editor" element={<MutationServiceMenu />} />

                <Route path="/digitalmodel" element={<DigitalModelMenu />} />
                <Route path="/digitalmodel/list" element={<AiReply />} />
                <Route path="/digitalmodel/creator" element={<AiReply />} />
                <Route path="/digitalmodel/photo/create/:photo_data" element={<PortraitPhotoCreator />} />
                <Route path="/digitalmodel/editor/:digitalmodel_data" element={<DigitalModelEditor />} />

                <Route path="/feed" element={<Feed />} />
                <Route path="/feed/:dm_uuid" element={<FeedForDM />} />

                <Route path="/chat/:dm_uuid" element={<DMChat />} />

                {/* <Route path="/chatdemo" element={<Chat />} /> */}
                {/* <Route path="/aireply" element={<AiReply />} /> */}

            </Routes>

            {showUserPopup && <UserPopup onClose={closeUserPopup} />}
            {showPaymentPopup && (
                <div className="user-popup">
                <PaypalPayment onClose={closePaymentPopup}/>
                </div>
            )}

            {!isLoggedIn() && (
                <div className='copyright' style={{padding:'10px'}}>
                © 2024 Darkforce.AI · All rights reserved.
                </div>
            )}

        </div>
        </BrowserRouter>
    );
}
export default App;

