import React, { useState } from 'react';
import { FaceswapVideoWorkflows } from '../../../config';
import MultipleFaceVideoWorkflow from './MutipleFaceVideoWorkflow';
import SingleFaceVideoWorkflow from './SingleFaceVideoWorkflow';
import '../../../css/CreatorServiceMenu.css';

function CreatorVideoMenu({onCreatorClose,  onComplete}) {
    const [selectedWorkflow, setSelectedWorkflow] = useState(null)

    function handleClick(wf) {
        setSelectedWorkflow(wf)
    }

    function close() {
        setSelectedWorkflow(null)
    }

    return (
        <div className="service-menu-container">
            {selectedWorkflow==null &&
                <div className="service-grid">
                {FaceswapVideoWorkflows.map((workflow, index) => (
                <div className="service-card" onClick={() => handleClick(workflow)}>
                    <img key={index} src={workflow.imageUrl} className="service-image" />
                    <div className="service-info">
                        <h3>{workflow.name}</h3>
                    </div>
                </div>
                ))}
                </div>
            }
            {(selectedWorkflow && selectedWorkflow.workflow_name === 'MultipleFaceVideoWorkflow') && <MultipleFaceVideoWorkflow workflow={selectedWorkflow} onCloseWorkflow={close} onComplete={onComplete} />}
            {(selectedWorkflow && selectedWorkflow.workflow_name === 'SingleFaceVideoWorkflow') && <SingleFaceVideoWorkflow workflow={selectedWorkflow} onCloseWorkflow={close} onComplete={onComplete} />}
        </div>

    )
}

export default CreatorVideoMenu;