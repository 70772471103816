import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Host, ImageModels } from '../../../config';
import { UpdateUserPoints } from '../../../user/User';
import '../../../css/ImageGeneration.css';
import T from '../../../language'
import { HttpPostForm, HttpGet } from '../../../http/HttpClient';
import { decodeToJson } from '../../common/util';
import { useParams } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import { imageDataURLtoFile } from '../../common/util';

function PortraitPhotoCreator() {
  const { photo_data } = useParams();
  const [photo] = useState(decodeToJson(photo_data));
  const [description, setDescription] = useState('');
  const [prompt, setPrompt] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [strength, setStrength] = useState(0.6);

  const [seedValue, setSeedValue] = useState(0);
  const [numOfImages, setNumOfImages] = useState(1);
  const [selectedModel, setSelectedModel] = useState(ImageModels[0].model_id); // Default to the first model
  const [message, setMessage] = useState(null);
  const [taskUUID, setTaskUUID] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!ableToSubmit()) {
      return;
    }

    var resizedImageFile = null;
    if (imagePreview !== '') {
      resizedImageFile = imageDataURLtoFile(imagePreview)
      if (resizedImageFile === null) {
        setMessage("failed to convert resized image file");
        return;
      } 
    }

    const formData = new FormData();
    formData.append("use_ip_adapter", "true");
    formData.append('description', description);
    formData.append('bucket', photo.bucket);
    formData.append('object', photo.object);
    formData.append('dm_uuid', photo.uuid);

    formData.append('strength', strength);
    formData.append('prompt', prompt);
    formData.append('seed', seedValue);
    formData.append('image_number', numOfImages);
    formData.append('model_id', selectedModel); // Use selected model_id
    formData.append('hd_enabled', "true");

    if (resizedImageFile !== null) {
      formData.append('file', resizedImageFile);
    }

    localStorage.setItem("digitalmodel_last_description", description);
    localStorage.setItem("digitalmodel_last_prompt", prompt);
    localStorage.setItem("digitalmodel_last_model_id", selectedModel);
    localStorage.setItem("digitalmodel_last_seed", seedValue+"");

    try {
      HttpPostForm('/digitalmodel/task/add', formData)
        .then((response) => {
          const resp = response.data;
          console.log(resp.data);
          if (resp.error) {
              setMessage(resp.error);
          } else if (resp.data) {
              setMessage('Pending processing...');
              console.log(resp.data.task_uuid)
              setTaskUUID(resp.data.task_uuid);
              // Clear the state
              setPrompt('');
              setDescription('');
              setImageFile(null);
              setImagePreview('');
              UpdateUserPoints();
          }
        })
        .catch((err) => {
          setMessage(err.response.data.error);
          console.log(err.response);
        });
      setMessage('Submitting...');
    } catch (error) {
      console.error('Error uploading form data', error);
      setMessage('Error submitting the form');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMessage(null);
      resizeFile(file).then((resized) => {
        // setResizedImage(resized);
        const img = new Image();
        img.onload = () => {
          // setImageWidth(img.width);
          setImagePreview(resized);
        }
        img.src = resized;
      });
      // setImageFile(file);
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   setImagePreview(e.target.result);
      // };
      // reader.readAsDataURL(file);
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024, // max width
        1024, // max height
        'JPEG', // output format
        100, // quality
        0, // rotation
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const closeForm = () => {
  };

  const ableToSubmit = () => {
    return message == null;
  };

  const handleImageClick = (img) => {
    setSelectedImage(img);
  };

  const handleOverlayClick = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    if (taskUUID) {
        const interval = setInterval(async () => {
          HttpGet(`${Host.Server}/gallery/task/result/${taskUUID}`)
          .then((response)=>{
            console.log(response);
            const resultData = response.data.data;
            if (resultData.result && resultData.result.length > 0) {
                console.log(resultData.result);
                setImages(resultData.result);
                clearInterval(interval);
            }  
          })
          .catch((error)=>{
            console.log('Error fetching task result', error);
          })
        }, 4000);
        return () => clearInterval(interval);
    } else {
      var v = localStorage.getItem("digitalmodel_last_prompt")
      if (v !== null) {
        setPrompt(v);
      }

      v = localStorage.getItem("digitalmodel_last_description");
      if (v !== null) {
        setDescription(v);
      }

      v = localStorage.getItem("digitalmodel_last_model_id");
      if (v !== null) {
        setSelectedModel(v);
      }
  
      v = localStorage.getItem("digitalmodel_last_seed");
      if (v !== null) {
        setSeedValue(parseInt(v))
      }
    }
  }, [taskUUID]);

  const reload = () => {
    setTaskUUID('');
    setImages([]);
    setSelectedImage(null);
  }

  return (
    <div>
      {!taskUUID && (
        <div className="generation-container" >
          <label>{T("Create a Portrait")}</label>
          <div className="input-container">
            <label htmlFor="modelSelect">Select Model:</label>
            <select
              id="modelSelect"
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
            >
              {ImageModels.map((model) => (
                <option key={model.model_id} value={model.model_id}>
                  {model.name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-container">
            <label htmlFor="desciption">{T("description")}</label>
            <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
          </div>
          <div className="input-container">
            <label htmlFor="prompt">{T("prompt")}</label>
            <textarea id="prompt" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
          </div>
          <div className="preview-container">
            <img src={photo.img_url} />
          </div>

          <div className="preview-container">
            <label htmlFor="imageInput">Upload Reference Image:</label>
            <input id="imageInput" type="file" accept="image/*" onChange={handleFileChange} />
            {imagePreview && <img src={imagePreview} alt="Preview" />}
          </div>

          <div className="input-container">
            <label htmlFor="seedValue">Seed:</label>
            <input type="text" id="seedValue" value={seedValue} onChange={(e) => setSeedValue(e.target.value)} />
          </div>

          <div className="input-container">
            <label htmlFor="strengthValue">Strength:</label>
            <input type="text" id="strengthValue" value={strength} onChange={(e) => setStrength(e.target.value)} />
          </div>

          <div className="input-container">
            <label htmlFor="numOfImagesDropDown">Number of Images:</label>
            <select
              id="numOfImagesDropDown"
              value={numOfImages}
              onChange={(e) => setNumOfImages(e.target.value)}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="4">4</option>
              <option value="6">6</option>
              <option value="10">10</option>
            </select>
          </div>
          <div className="button-container">
            <button onClick={(e) => handleSubmit(e)}>Submit</button>
            <button onClick={(e) => closeForm()}>Back</button>
          </div>
          {message && <div className={`message ${message === 'SUCCESS' ? 'success' : 'error'}`}>{message}</div>}
        </div>
      )}
      {taskUUID && images.length === 0 && (
        <div className="loading-spinner" onClick={closeForm}>
          <p>Generating... Please wait.</p>
          <div className="spinner"></div>
          <p>{T('You can close and check the result in "Gallery"')}</p>
          <button onClick={(e) => {
            e.preventDefault();
            closeForm();
            }}>Close</button>
        </div>
      )}
      {taskUUID && images.length > 0 && (
        <div className="success-message">
          <h2>SUCCESS</h2>
          <div className="image-grid">
            {images.map((img, index) => (
              <img key={index} src={img.url} alt={`Result ${index}`} onClick={() => handleImageClick(img)} />
            ))}
          </div>
          <button onClick={(e) => {
            e.preventDefault();
            closeForm();
            }}>Close</button>
        </div>
      )}
      {selectedImage && (
        <div className="overlay" onClick={handleOverlayClick}>
          <img src={selectedImage.url} alt="Full Screen" className="full-screen-image" />
        </div>
      )}
    </div>
  );
}

export default PortraitPhotoCreator;
