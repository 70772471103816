import React, { useState, useRef, useEffect } from 'react';

function ImageMasker({ imageSrc, onMaskSave }) {
  const imageCanvasRef = useRef(null);
  const maskCanvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [penSize, setPenSize] = useState(15);
  const [penColor, setPenColor] = useState('white');

  const handleMouseDown = (e) => {
    startDrawing(e.clientX, e.clientY);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;
    draw(e.clientX, e.clientY);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    saveMask();
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    startDrawing(touch.clientX, touch.clientY);
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    if (!isDrawing) return;
    const touch = e.touches[0];
    draw(touch.clientX, touch.clientY);
  };

  const handleTouchEnd = () => {
    setIsDrawing(false);
    saveMask();
  };

  const startDrawing = (x, y) => {
    const canvas = maskCanvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(x - rect.left, y - rect.top);
    setIsDrawing(true);
  };

  const draw = (x, y) => {
    const canvas = maskCanvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const ctx = canvas.getContext('2d');
    ctx.lineTo(x - rect.left, y - rect.top);
    ctx.strokeStyle = 'blue';
    ctx.lineWidth = penSize; // Adjust the pen thickness as needed
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.shadowBlur = 10;
    ctx.shadowColor = 'blue';
    ctx.stroke();
  };

  useEffect(() => {
    const imageCanvas = imageCanvasRef.current;
    const imageCtx = imageCanvas.getContext('2d');
    const maskCanvas = maskCanvasRef.current;

    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      imageCanvas.width = img.width;
      imageCanvas.height = img.height;
      maskCanvas.width = img.width;
      maskCanvas.height = img.height;

      // Draw the original image on the image canvas
      imageCtx.drawImage(img, 0, 0);

      setWidth(img.width);
      setHeight(img.height);
    };

  }, [imageSrc]);

  const saveMask = () => {
    const maskCanvas = maskCanvasRef.current;
    const offscreenCanvas = document.createElement('canvas');
    const offscreenCtx = offscreenCanvas.getContext('2d');

    offscreenCanvas.width = maskCanvas.width;
    offscreenCanvas.height = maskCanvas.height;

    const imageData = maskCanvas.getContext('2d').getImageData(0, 0, maskCanvas.width, maskCanvas.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      if (data[i + 3] != 0) {
        data[i] = 255; // R
        data[i + 1] = 255; // G
        data[i + 2] = 255; // B
      } else {
        data[i] = 0;
        data[i + 1] = 0;
        data[i + 2] = 0;
        data[i + 3] = 255;
      }
    }

    // Put the modified image data onto the destination canvas
    offscreenCtx.putImageData(imageData, 0, 0);

    const maskDataURL = offscreenCanvas.toDataURL();

    // Log or save the mask data URL
    onMaskSave(maskDataURL);
  };

  return (
    <div style={{ position: 'relative', width: `${width}px`, height: `${height}px` }}>
      <canvas
        ref={imageCanvasRef}
        style={{ position: 'absolute', top: 0, left: 0 }} // Hide the original image canvas
      />
      <canvas
        ref={maskCanvasRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp} // Stop drawing when the mouse leaves the canvas
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          border: '1px solid black',
          cursor: 'crosshair',
          position: 'absolute',
          background: 'transparent',
          top: 0,
          left: 0,
          touchAction: 'none' // Prevent default touch actions
        }}
      />
        <div style={{ position: 'absolute', top: 10, right: 10 }}>
            <button onClick={(e)=>setPenColor('white')}  style={{backgroundColor: 'white'}}></button>
            <button onClick={(e)=>setPenColor('black')} style={{backgroundColor: 'black'}}></button>
            <button onClick={(e)=>setPenColor('red')}  style={{backgroundColor: 'red'}}></button>
            <button onClick={(e)=>setPenColor('green')}  style={{backgroundColor: 'green'}}></button>
            <button onClick={(e)=>setPenColor('blue')}  style={{backgroundColor: 'blue'}}></button>
            <button onClick={(e)=>setPenColor('yellow')}  style={{backgroundColor: 'yellow'}}></button>

            <button onClick={(e)=>{
                if (penSize < 100) {
                    setPenSize(penSize+5);
                }
            }}>+</button>
            <button onClick={(e)=>{
                if (penSize > 5) {
                    setPenSize(penSize-5);
                }
            }}>-</button>
        </div>
    </div>
  );
}

export default ImageMasker;
