import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ImageModels, Host, AigcWorkflows } from '../../../config';
import '../../../css/Gallery.css';
import T from '../../../language';
import { HttpPost, HttpGet } from '../../../http/HttpClient';
// import ImageMasker from '../../common/ImageMasker';
import ImageEditor from '../../common/ImageEditor';

function Gallery() {
    const [activeImage, setActiveImage] = useState(null);
    const [userImages, setUserImages] = useState([]);
    const [modelMap, setModelMap] = useState({});
    const [workflowMap, setWorkflowMap] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(10); // Define page size here
    const [isEditMode, setIsEditMode] = useState(false)
    const [pendingTaskCount, setPendingTaskCount] = useState(0)
    const [isSubmitingForDigitalModel, setIsSubmitingForDigitalModel] = useState(0)
    const [digitalModelName, setDigitalModelName] = useState("")

    const openImage = (img) => {
        setActiveImage(img);
        if (img.status==="0") {
            HttpGet("/gallery/task/count/pending/" + img.task_uuid)
            .then((response) => {
                setPendingTaskCount(response.data.data.result)
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        document.body.style.overflow = 'hidden';
        console.log(isEditMode)
        console.log(activeImage)
    };

    const closeImage = () => {
        if (isSubmitingForDigitalModel) {
            return
        }

        if (!isEditMode) {
            setActiveImage(null);
        }
        document.body.style.overflow = 'auto';
    };

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    const onDigitalModelNameChange = (e) => {
        setDigitalModelName(e.target.value);
    };


    const fetchUserImages = async (page) => {
        try {
            const url = `${Host.Server}/gallery/browse?start_page=${page}&page_size=${pageSize}`;
            const token = Cookies.get("X-API-TOKEN");
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            };
            const response = await axios.get(url, { headers });
            const result = response.data.data.result;
            console.log(result);
            setUserImages(result);
        } catch (error) {
            console.error('Error fetching user images:', error);
        }
    };

    useEffect(() => {
        fetchUserImages(currentPage);
    }, [currentPage]);

    useEffect(() => {
        var tempMap = {};
        ImageModels.map((model, index) => {
            tempMap[model['model_id']] = model;
            return true;
        });
        setModelMap(tempMap);

        var tempWorkflowMap = {};
        AigcWorkflows.map((workflow, i) => {
            tempWorkflowMap[workflow.id] = workflow;
            return true;
        })
        setWorkflowMap(tempWorkflowMap);
    }, []);

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const getModelName = (modelId) => {
        if (modelMap[modelId] == undefined ||
            modelMap[modelId].name === undefined) {
                return T("unknown model");
            }

        return T(modelMap[activeImage.model_id].name);
    }

    const getWorkflowName = (workflowId) => {
        if (workflowMap[workflowId] == undefined ||
            workflowMap[workflowId].name === undefined) {
                return T("unknown workflow");
            }

        return T(workflowMap[workflowId].name);
    }

    const handleDelete = (img) => {
        const url = `/gallery/task/image/delete`;
        HttpPost(url, {
            'task_uuid': img.task_uuid,
            'bucket': img.bucket,
            'object': img.object
        })
        .then((response) => {
            console.log(response.data)
            fetchUserImages(currentPage)
        })
        .catch((error) => {
            console.log(error)
        })
    };

    const handleCancelTask = (task) => {
        const url = `/gallery/task/cancel`;
        HttpPost(url, {
            'task_uuid': task.task_uuid,
        })
        .then((response) => {
            console.log(response.data)
            fetchUserImages(currentPage)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const handleEdit = () => {
        setIsEditMode(true)
    };

    const saveAsNewModel = () => {
        // setIsEditMode(true)
        console.log(activeImage)
        setIsSubmitingForDigitalModel(true)
        // HttpPost('/digitalmodel/model/add', {
        //     'task_uuid': activeImage.task_uuid,
        //     'bucket': activeImage.bucket,
        //     'object': activeImage.object
        // })
    };

    const saveAsPhoto = () => {
        // setIsEditMode(true)
        console.log(activeImage)

        HttpPost('/digitalmodel/photo/add', {
            'task_uuid': activeImage.task_uuid,
            'bucket': activeImage.bucket,
            'object': activeImage.object
        })
        .then((response)=>{
            console.log(response.data)
            fetchUserImages(currentPage)
        })
        .catch((error)=>{
            console.log(error)
        })
    };

    const swapSingleFace = () => {
        HttpPost('/digitalmodel/task/faceswap/single', {
            "dm_task_uuid": activeImage.task_uuid,
            "target_bucket": activeImage.bucket,
            "target_object": activeImage.object,
            "face_retouch": "true"
        })
        .then((response) => {
            console.log(response)
            fetchUserImages(currentPage)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const convertToLocalDateTime = (timestamp) => {
        // Create a Date object from the timestamp string
        const date = new Date(timestamp);
      
        // Check if the date is valid
        if (isNaN(date.getTime())) {
          return 'Invalid Date';
        }
      
        // Get the local datetime string
        const localDateTime = date.toLocaleString();
      
        return localDateTime;
      };

    return (
        <div className="gallery-container">
            <div className="image-grid">
                {userImages.map((img, index) => (
                    <div className="image-card" key={index} onClick={() => openImage(img)}>
                        {img.img_url !== "" && img.img_url !== undefined && <img src={img.img_url} alt="" />}
                        {(img.img_url === "" || img.img_url === undefined) && img.status === "0" && <img src="./image/pending.jpg" alt="" />}
                        {(img.img_url === "" || img.img_url === undefined) && img.status === "1" && <img src="./image/loading-green.gif" alt="" />}
                        {(img.img_url === "" || img.img_url === undefined) && img.status === "3" && <img src="./image/icon_error.jpg" alt="" />}
                    </div>
                ))}
            </div>

            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 0}>Previous</button>
                <button onClick={handleNextPage} disabled={userImages.length == 0}>Next</button>
            </div>

            {activeImage && activeImage.status === "0" && (
                <div className="image-overlay" onClick={closeImage}>
                    <div className="overlay-content">
                            <p style={{ color: "black" }}>Prompt: {activeImage.prompt}</p>
                            <p style={{ color: "black" }}>Seed: {activeImage.seed}</p>
                            <p style={{ color: "black" }}>Created At: {convertToLocalDateTime(activeImage.created_at)}</p>
                            <p style={{ color: "black" }}>Pending Tasks in Queue: {pendingTaskCount}</p>
                            <button onClick={()=>{handleCancelTask(activeImage)}} className="delete-button">{T("DELETE TASK")}</button>
                    </div>
                </div>
            )}

            {activeImage && activeImage.status === "1" && (
                <div className="image-overlay" onClick={closeImage}>
                    <div className="overlay-content">
                            <p style={{ color: "black" }}>Prompt: {activeImage.prompt}</p>
                            <p style={{ color: "black" }}>Seed: {activeImage.seed}</p>
                            <p style={{ color: "black" }}>Created At: {convertToLocalDateTime(activeImage.created_at)}</p>
                            <button onClick={()=>{handleCancelTask(activeImage)}} className="delete-button">{T("DELETE TASK")}</button>
                    </div>
                </div>
            )}

            {activeImage && activeImage.status === "3" && (
                <div className="image-overlay" onClick={closeImage}>
                    <div className="overlay-content">
                            <p style={{ color: "black" }}>Prompt: {activeImage.prompt}</p>
                            <p style={{ color: "black" }}>Seed: {activeImage.seed}</p>
                            <p style={{ color: "black" }}>Created At: {convertToLocalDateTime(activeImage.created_at)}</p>
                            <button onClick={()=>{handleCancelTask(activeImage)}} className="delete-button">{T("DELETE TASK")}</button>
                   </div>
                </div>
            )}


            {activeImage && (activeImage.status==="2" || activeImage.status===undefined) && !isEditMode && (
                <div className="image-overlay" onClick={closeImage}>
                    {activeImage.img_url !== "" && (
                        <div className="overlay-content">
                            <img src={activeImage.img_url} alt="Zoomed"/>
                            {/* <ImageMasker imageSrc={activeImage.img_url}/> */}
                            {
                                activeImage.model_id !== undefined && activeImage.model_id !== "" && (
                                <span style={{ color: "black" }}>Model: {getModelName(activeImage.model_id)}</span>
                                )
                            }
                            {
                                activeImage.workflow_id !== undefined && activeImage.workflow_id !== "" && (
                                    <span style={{ color: "black" }}>Workflow: {getWorkflowName(activeImage.workflow_id)}</span>
                                    )
                            }
                            <p style={{ color: "black" }}>Prompt: {activeImage.prompt}</p>
                            <p style={{ color: "black" }}>Seed: {activeImage.seed}</p>
                            <button onClick={()=>{handleDelete(activeImage)}} className="delete-button">{T("DELETE")}</button>
                            <button onClick={(e)=>{
                                e.preventDefault();
                                e.stopPropagation();
                                handleEdit();
                                }} className="delete-button">{T("EDIT")}</button>
                            {
                                activeImage.digital_model_name != "" && (
                                    <span style={{ color: "black" }}>Used for model: {activeImage.digital_model_name}</span>
                                )
                            }
                            {
                                activeImage.task_type !=="4" && activeImage.digital_model_uuid === "" && (
                                    <button onClick={(e)=>{
                                        e.preventDefault();
                                        e.stopPropagation();
                                        saveAsNewModel();
                                        }} className="delete-button">{T("Create An New Model")}</button>        
                                )                                    
                            }
                            {
                                activeImage.task_type==="4" && activeImage.digital_model_uuid === "" && activeImage.is_photo==="false" && (
                                    <div>
                                    <button onClick={(e)=>{
                                        // e.preventDefault();
                                        // e.stopPropagation();
                                        saveAsPhoto();
                                        }} className="delete-button">{T("Use For Photo")}
                                    </button>
                                    <button onClick={(e)=>{
                                        // e.preventDefault();
                                        // e.stopPropagation();
                                        swapSingleFace();
                                        }} className="delete-button">{T("Swap With Digital Model Face")}
                                    </button>
                                    </div>
                                )
                            }
                            
                            {
                                isSubmitingForDigitalModel && (
                                <div>
                                    <input type="text" value={digitalModelName} onChange={onDigitalModelNameChange}/>
                                    <button onClick={(e)=>{
                                        e.preventDefault();
                                        e.stopPropagation();

                                        HttpPost('/digitalmodel/model/add', {
                                            'name': digitalModelName,
                                            'task_uuid': activeImage.task_uuid,
                                            'bucket': activeImage.bucket,
                                            'object': activeImage.object
                                        })
                                        .then((response)=>{
                                            console.log(response)
                                            setIsSubmitingForDigitalModel(false);
                                            setActiveImage(null);
                                            document.body.style.overflow = 'auto';
                                        })
                                        .catch((error)=>{
                                            console.log(error)
                                        })

                                    }}>Create New Digial Model</button>
                                </div>
                                )
                            }
                        </div>
                    )}
                </div>
            )}

            {activeImage && (activeImage.status==="2" || activeImage.status === undefined) && isEditMode && activeImage.img_url !== "" && (
                <div className="image-overlay">
                    <div className="overlay-content">
                    <ImageEditor
                        imageUrl={activeImage.img_url}
                        imageBucket={activeImage.bucket}
                        objectName={activeImage.object}
                        onClose={()=>{
                            setActiveImage(null);
                            setIsEditMode(false);
                            document.body.style.overflow = 'auto';
                        }}
                    />
                    </div>
                </div>                
            )}

        </div>
    );
}

export default Gallery;
