
function toKey(s) {
    return s.trim().toLowerCase().replaceAll(" ", "_").replaceAll('"', '')
}

function getLocale() {
    const urlParams = new URLSearchParams(window.location.search);
    var locale = urlParams.get('locale');
    if (locale) {
        return locale
    }
    locale = navigator.language
    if (!locale) {
        locale = navigator.browserLanguage
    }
    return locale
}

const translation_cn = {
    "single_face_swap": "单图换脸",
    "creator": "创作",
    "gallery": "图库",
    "submit": "提交",
    "back": "返回",
    "source_face_image": "人脸图片",
    "target_image": "待替换图片",
    "cartoon(anything)": "卡通(anything)",
    "cartoon(hentai)": "卡通(hentai)",
    "cartoon(chinese)": "卡通(中国古风)",
    "cartoon(pony_based)": "卡通(Pony)",
    "realistic(magicmix)": "写实(magicmix)",
    "realistic(asianporn)": "写实(asianporn)",
    "realistic(Pony)": "写实(pony)",
    "realistic(chinese)": "写实(中国古风)",
    "realistic(pony_based)": "写实(Pony)",
    "generated_video_is_limitd_to_15_seconds": "生成视频不超过15秒",
    "unrestricted_ai_visuals_for_everyone": "无限制实用AI视觉创作",
    "image_face_swap": "图片换脸",
    "video_face_swap": "视频换脸",
    "Image Generating": "AI生图",
    "buy_coins": "购买点数",
    "single_face_wap": "单图换脸",
    "multi_face_swap": "多图换脸",
    "multi-faces_swap": "多图换脸",
    "ai_portrait": "AI人像",
    "unrestricted_creation": "无限制创作",
    "High Quality Face Swap": "高质量换脸",
    "aigc": "AI内容生成",
    "unrestricted": "无限制",
    "customized_models": "优化模型",
    "single_face_swap_video": "视频单人换脸",
    "multi_face_swap_video": "视频多人换脸",
    "using_portrait": "使用人像",
    "using_text/image": "使用图文",
    'you_can_close_and_check_the_result_in_gallery': '你可以关闭当前页面，然后在"图库"查看结果',

    'login': '登录',
    'username': '用户名',
    'password': '密码',
    'password(type_again)': '密码(再次输入)',
    'invite_code(optional)': '邀请码(选填)',
    'create_new_account': '创建新用户',
    'create': '创建',
    'back_to_login': '回到登录',
    "Membership & Points": "会员等级与点数",
    'membership_features': '会员特权',
    "New User: Can use faceswap for single face in 2 photos": "新会员: 能对单张图片中单个人像进行换脸",
    "Basic: Can use faceswap for multiple faces in multiple photos": "基础会员: 能同时对多张图片中多个人像进行换脸",
    "Premium: Can use faceswap for videos": "尊享会员: 能对视频进行换脸",
    "VIP: Can generate images with different finetuned visual models and workflows": "VIP会员: 能够使用多个高品质AI模型进行多种类型的AI图像生成",
    "Select Amount (USD)": "会员购买(价格单位:美元)",
    "Basic membership + 99 points": "基础会员(终身)+99点",
    "Premium + 800 points for 1 year": "尊享会员(1年)+800点",
    "VIP membership + 1000 points": "VIP会员(终身)+1000点",
    "500 points": "500点",
    "1000 points": "1000点",

    "require_basic": "需要基础会员",
    "require_premium": "需要尊享会员",
    "require_vip": "需要VIP会员",

    "adult": "预制场景",
    "freestyle": "自由创作",

    "new_membership": "新会员",
    "basic_membership": "基础会员",
    "premium_membership": "尊享会员",
    "vip_membership": "VIP会员",

}

const translation_en = {
    "new_membership": "NEW",
    "basic_membership": "BASIC",
    "premium_membership": "PREMIUM",
    "vip_membership": "VIP",
}

const T = (s) => {
    var translation = translation_en;

    var locale = getLocale()    
    if (locale === "zh-Hans" || locale === "zh-CN") {
        translation = translation_cn;
    }

    if (translation[s] !== undefined) {
        return translation[s]
    }

    var k = toKey(s)
    var v = translation[k];
    return v ? v : s;
}

export default T