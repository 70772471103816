import {Host} from '../config'
import axios from 'axios';
import Cookies from 'js-cookie';
import {UserPointEventEmitter} from '../event/event.js'
import { HttpGet } from '../http/HttpClient.js';


export const UpdateUserPoints = () => {
    HttpGet('/user/points')
    .then((response) => {
      console.log(response)
      var result = response.data.data.result
      UserPointEventEmitter.emit('updatePoints', result);
    })
    .catch((err) => {
      console.log(Host.Server)
      console.log('API URL:', process.env.REACT_APP_API_URL);
      console.log(err)
    })
  }