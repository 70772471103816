import React, { useState } from 'react';
import Gallery from './gallery/Gallery';
import CreatorServiceMenu from './creator/CreatorServiceMenu';

function GeneratorServiceMenu() {
    const [selectedService, setSelectedService] = useState("creator");

    function closeCreatorMenu() {
        setSelectedService("gallery")
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                <div style={{ margin: '0 10px', padding: '10px', cursor: 'pointer', borderBottom: selectedService === 'creator' ? '3px solid blue' : '' }} 
                     onClick={() => { setSelectedService("creator"); } }>
                    Creator
                </div>
                <div style={{ margin: '0 10px', padding: '10px', cursor: 'pointer', borderBottom: selectedService === 'gallery' ? '3px solid blue' : '' }} 
                     onClick={() => setSelectedService('gallery')}>
                    Gallery
                </div>
            </div>

            {selectedService === "creator" && <CreatorServiceMenu key={new Date().getTime()} onCreatorModeClose={closeCreatorMenu} />}
            {selectedService === "gallery" && <Gallery />}
        </div>
    )
}

export default GeneratorServiceMenu;