import React, { useState, useEffect } from 'react';
import '../../css/Feed.css';
import { HttpPost, HttpGetWithoutToken, HttpGet } from '../../http/HttpClient';
import { convertToLocalDateTime, isLoggedIn } from '../common/util';
import T from '../../language';
import { Link, useParams } from "react-router-dom";

function FeedForDM() {
    const { dm_uuid } = useParams();
    const [feeds, setFeeds] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(20); // Define page size here
    const [inputComment, setInputComment] = useState(false)
    const [signInMessage, setSignInMessage] = useState("")
    const [newComment, setNewComment] = useState("")
    const [targetFeedUuid, setTargetFeedUuid] = useState(null)
    const [activeImage, setActiveImage] = useState(null)

    const fetchUserImages = async (page) => {
        HttpGetWithoutToken(`/feed/list/${dm_uuid}/${currentPage}/${pageSize}`)
        .then((response)=>{
            const result = response.data.data.result
            console.log(result)
            setFeeds(result)
        })
        .catch((error)=>{
            console.log(error)
        })
    };

    useEffect(() => {
        fetchUserImages(currentPage);
    }, [currentPage]);

    const openImage = (img) => {
        setActiveImage(img);
        document.body.style.overflow = 'hidden';
    };

    const closeImage = () => {
        setActiveImage(null);
        document.body.style.overflow = 'auto';
    };


    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    // useEffect(() => {
    //     return () => {
    //         setFeeds(mockFeeds)
    //         document.body.style.overflow = 'auto';
    //     };
    // }, []);
    
    const addComment = (feedUuid) => {
        setTargetFeedUuid(feedUuid)
        if (!isLoggedIn()) {
            setSignInMessage(T("Needs to sign in first to comment"))
            return
        }
        setInputComment(true)
    }

    const submitComment = (e)=>{
        console.log(newComment)
    }

    return (
        <div>
            <Link to={"/chat/" + dm_uuid}>{T("Chat")}</Link>
            <div className="feed-container">
                {feeds.map((feed, index) => (
                    <div className="feed-row" key={index}>
                        <div className='feed-row-profile-image'><img src={feed.profile_image}/></div>
                        <div>
                            <div className='feed-row-title'>{feed.name}</div>
                            <div className='feed-row-text'>{feed.text}</div>
                            <div className='photo-grid'>
                                {
                                    feed.photos.map((photo, i) => (
                                        <div>
                                            <img src={photo} onClick={(e)=>{
                                                openImage(photo)
                                            }}/>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='feed-row-lastline'>
                                <div className='feed-row-timestamp'>{convertToLocalDateTime(feed.created_at)}</div>
                                <div className='feed-row-addcomment' onClick={(e)=>{addComment(feed.feed_uuid);}}>..</div>
                            </div>
                            {
                                    feed.comments.map((comment, i) => (
                                        <div  className='feed-row-comment'>
                                            <span style={{color:"lightblue", whiteSpace:"nowrap"}}>{comment.dm_name}</span>: {comment.comment}
                                        </div>
                                    ))
                                }

                        </div>
                    </div>
                ))}
                
            </div>
            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 0}>Previous</button>
                <button onClick={handleNextPage} disabled={feeds.length == 0}>Next</button>
            </div>

            {activeImage && (
                <div className="image-overlay" onClick={closeImage}>
                    <div className="overlay-content">
                        <img src={activeImage} alt="Zoomed"/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default FeedForDM;
