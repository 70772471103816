import React, { useState } from 'react';
import CreatorForm from './CreatorForm';
import AigcWorkflowCreatorForm from './AigcWorkflowCreatorForm';
import InpaintingCreatorForm from './InpaintingCreatorForm';
import '../../../css/CreatorServiceMenu.css';
import { ImageServices } from '../../../config';
import T from '../../../language';

function CreatorServiceMenu({ onCreatorModeClose }) {
  const [selecteServive, setSelectedService] = useState(null);

  function handleClick(service) {
    setSelectedService(service);
  }

  function close() {
    setSelectedService(null);
  }

  return (
    <div className="service-menu-container">
      {selecteServive == null && (
        <div className="service-grid">
          {ImageServices.map((service, index) => (
            <div className="service-card" key={index} onClick={() => handleClick(service)}>
              <img src={service.imageUrl} alt={`Placeholder ${index + 1}`} className="service-image" />
              <div className="service-info">
                <h3>{T(service.name)}</h3>
              </div>
            </div>
          ))}
        </div>
      )}
      {selecteServive != null && (selecteServive.id === "1" || selecteServive.id === "2") && <CreatorForm service={selecteServive} onCloseCreatorForm={close} />}
      {selecteServive != null && selecteServive.id == "3" && <AigcWorkflowCreatorForm service={selecteServive} onCloseCreatorForm={close} />}
      {selecteServive != null && selecteServive.id == "4" && <InpaintingCreatorForm service={selecteServive} onCloseCreatorForm={close} />}
    </div>
  );
}

export default CreatorServiceMenu;
