export const Host = {
    Server: process.env.REACT_APP_API_URL
}

export const Memberships = {
    0: 'New_Membership',
    1: "Basic_Membership",
    2: "Premium_Membership",
    3: "VIP_Membership",
}

export const Features = [
    { title: 'Image Face Swap', 
    service: 'FaceSwap', 
    membership: 0,
    description: 'Swap faces in photos', 
    icon: './image/faceswap_single.jpg' },

    { title: 'Video Face Swap', 
    service: 'FaceSwapVideo', 
    membership: 1,
    description: 'Swap faces in video', 
    icon: './image/faceswap_multi.jpg' },

    { title: 'Change Anything', 
        service: 'editor', 
        membership: 2,
        description: 'Mutate Images', 
        icon: './image/faceswap_multi.jpg' },
    
    { title: 'Create Anything', 
    service: 'creator',
    membership: 3,
     description: 'Generate AI images', 
     icon: './image/icon_aigc.jpg' },

     { title: 'Digital Model', 
        service: 'digitalmodel',
        membership: 3,
         description: 'Digital Model', 
         icon: './image/icon_aigc.jpg' },
    
     
    // { title: 'Story(小说生成)', service: 'StoryWriter', description: 'Generate stories', icon: '📖' },
    // { title: 'Erotic Chat(成人聊天)', service: 'AdultChat', description: 'Adult chat', icon: '💬' },
    // { title: 'Buy Coins', 
    //  service: 'PaypalPayment',
    //  description: 'Purchase credits', 
    //  icon: '💰' },
  ];


export const HomepageFeatureList = [
    {
        "id": "faceswap_video",
        "title": "Faceswap for Video",
        "text": "With just a few photos and a video clip, you can replace any number of characters in the video with specific individuals from the photos.",
        "image": "",
        "video": "https://storage.googleapis.com/darkforce-public/web/video/fw_video_demo.mp4"
    },
    {
        "id": "faceswap_single",
        "title": "Single Face Swap",
        "text": "Asian Fever or Blonde Addicted? No problem. Change the ladies to whoever your like in seconds. Just needs a single photo.",
        "image": "./image/sample/faceswap_single.jpg",
    },
    {
        "id": "faceswap_multiple",
        "title": "Multiple Faces Swap",
        "text": "Create a scene where Maradona and Batistuta celebrate with Messi at the World Cup! Just upload a few photos.",
        "image": "./image/sample/faceswap_multiple.jpg",
    },
    {
        "id": "digital_model",
        "title": "Digital Model",
        "text": "Create your dream girl and have her follow your commands? That's what AI is for. Create your digital model in seconds and make her do anything.",
        "image": "./image/sample/digital_model.jpg",
    },
    {
        "id": "change_cloth",
        "title": "Cloth Change",
        "text": "Try on different clothes for someone? Sure, our AI editor mode helps make your fantasy come true.",
        "image": "./image/sample/cloth_change.jpg",
    },
    {
        "id": "clear_background",
        "title": "Clear Background",
        "text": "Too many people in your photo background? Just erase them. That's the magic of AI!",
        "image": "./image/sample/clear_background.jpg",
    },
    {
        "id": "freestyle",
        "title": "Freestyle",
        "text": "Confused about AI terms like prompt, model, or Lora? Just use simple words—your creativity and imagination are all you need; we'll take care of the rest.",
        "image": "./image/sample/freestyle.jpg",
    },
    {
        "id": "adult",
        "title": "Adult Fantasy",
        "text": "And yes, AI isn't complete without supporting intimate scenes. We've carefully crafted predefined workflows with finetuned models so your adult fantasies can be illustrated with just a few clicks.",
        "image": "./image/sample/adult.jpg",
    },
]


// export const HomepageFeatureList = [
//     {
//         id: 'faceswap_single',
//         name: 'Face Swap',
//         icon: './image/faceswap_single.jpg',        
//     },
//     {
//         id: 'faceswap_video',
//         name: 'Face Swap',
//         icon: './image/faceswap_multi.jpg',        
//     },
//     {
//         id: 'portrait_based',
//         name: 'Digital Model',
//         icon: './image/model_4.png',        
//     },
//     {
//         id: 'change_image',
//         name: 'Change Anything',
//         icon: './image/universe_sphere.jpg',        
//     },
//     {
//         id: 'predefined',
//         name: 'Adult Specific',
//         icon: './image/icon_adult.jpg',        
//     },
//     {
//         id: 'freestyle',
//         name: 'Freestyle',
//         icon: './image/icon_aigc.jpg',        
//     },
// ]


export const FaceswapWorkflows = [
    {
        name: "Single Face Swap",
        workflow_name: "SingleFaceWorkflow",
        membership: 0,
        imageUrl: "./image/hd_1girl.jpeg",
        cost: "⭐ 1",
    },
    {
        name: "Multi Face Swap",
        workflow_name: "MultipleFaceWorkflow",
        membership: 0,
        imageUrl: "./image/3girl_hd.jpeg",
        cost: "⭐ 2",
    }
]

export const FaceswapVideoWorkflows = [
    {
        name: "Single Face Swap Video",
        workflow_name: "SingleFaceVideoWorkflow",
        membership: 1,
        imageUrl: "./image/video_single_faceswap.jpg",
        cost: "⭐ 20",
    },
    {
        name: "Multi Face Swap Video",
        workflow_name: "MultipleFaceVideoWorkflow",
        membership: 1,
        imageUrl: "./image/3girl_hd.jpeg",
        cost: "⭐ 30",
    }
]

export const ImageMutationServices = [

    // {
    //     id: "1",
    //     name: "Digital Model",
    //     membership: 2,
    //     imageUrl: "./image/model_2.png",
    //     require_image: true,
    //     use_ip_adapter: true,
    //     cost: "⭐ 10 for 1 image",
    // },

    {
        id: "4",
        name: "Change Everything",
        membership: 2,
        imageUrl: "./image/universe_sphere.jpg",
        require_image: true,
        use_ip_adapter: false,
        cost: "⭐ 10 for 1 image",
    },
]

export const DigitalModelServices = [

    {
        id: "2",
        name: "Create Photo",
        membership: 2,
        imageUrl: "./image/universe_sphere.jpg",
        require_image: true,
        use_ip_adapter: false,
        cost: "⭐ 10 for 1 image",
    },
]


export const ImageServices = [
    {
        id: "2",
        name: "Freestyle",
        membership: 3,
        imageUrl: "./image/model_3.png",
        require_image: false,
        use_ip_adapter: false,
        cost: "⭐ 10 for 1 image",
    },

    {
        id: "3",
        name: "Adult",
        membership: 3,
        imageUrl: "./image/icon_adult.jpg",
        require_image: false,
        use_ip_adapter: false,
        cost: "⭐ 10 for 1 image",
    },

]

export const ImageModels = [
    {
        model_id: "4",
        name: "Cartoon(Pony Based)",
    },
    {
        model_id: "5",
        name: "Cartoon(DreamShaper SDXL-Lightening)",
    },
    {
        model_id: "2",
        name: "Cartoon(Hentai,sd1.5)",
    },
    {
        model_id: "1",
        name: "Cartoon(Anything,sd1.5)",
    },
    {
        model_id: "3",
        name: "Cartoon(Chinese,sd1.5)",
    },
    // {
    //     model_id: "6",
    //     name: "Cartoon(PormMaster Anime, sd1.5)",
    // },
    {
        model_id: "106",
        name: "Realistic(DreamShaper SDXL-Lightening)",
    },
    {
        model_id: "101",
        name: "Realistic(MagicMix, sd1.5)",
    },
    {
        model_id: "104",
        name: "Realistic(AsianPorn, sd1.5)",
    },
    {
        model_id: "105",
        name: "Realistic(PonyRealism, sdxl)",
    },
    // {
    //     model_id: "108",
    //     name: "Realistic(PornMaster Pro, sd1.5)",
    // },
    // {
    //     model_id: "107",
    //     name: "Realistic(PornMaster Fantasy, sd1.5)",
    // },
]

export const AigcWorkflows = [
    {
        "id": "headback",
        "name": "Head Back",
        "prompt": "aisan, white dress",
        "demo_image": "./image/workflow_headback.jpg",
    },
    {
        "id": "breastshot",
        "name": "Breast Shot",
        "prompt": "aisan, long hair, white dress, on bed",
        "demo_image": "./image/workflow_breastshot.jpg",
    },
    {
        "id": "backsitsex",
        "name": "Cowgirl Back",
        "prompt": "aisan, long hair, bikini, on bed",
        "demo_image": "./image/workflow_cowgirl_back.jpg",
        "require_image": false,
    },
    {
        "id": "frontsitsex",
        "name": "Cowgirl Front",
        "prompt": "long hair,bra only, on bed",
        "demo_image": "./image/workflow_cowgirl_front.jpg",
        "require_image": false,
    },
    // {
    //     "id": "showass1",
    //     "name": "Show Me Your Ass #1",
    //     "prompt": "1girl",
    //     "demo_image": "",
    //     "require_image": false,
    // },

    // {
    //     "id": "showass2",
    //     "name": "Show Me Your Ass #2",
    //     "prompt": "1girl",
    //     "demo_image": "",
    //     "require_image": false,
    // },

    {
        "id": "doggy1",
        "name": "Doggy #1",
        "prompt": "aisan, long hair, white dress, on bed",
        "demo_image": "./image/workflow_doggy_1.jpg",
        "require_image": false,
    },
    {
        "id": "doggy2",
        "name": "Doggy #2",
        "prompt": "aisan, long hair, white dress, on bed",
        "demo_image": "./image/workflow_doggy_2.jpg",
        "require_image": false,
    },
    // {
    //     "id": "doggy3",
    //     "name": "Doggy #3",
    //     "prompt": "1girl",
    //     "demo_image": "",
    //     "require_image": false,
    // },
    {
        "id": "doggy4",
        "name": "Doggy #4",
        "prompt": "aisan, long hair, white dress, on bed",
        "demo_image": "./image/workflow_doggy_4.jpg",
        "require_image": false,
    },
    {
        "id": "lyingdown1",
        "name": "Missionary #1",
        "prompt": "1girl",
        "demo_image": "./image/workflow_missionary_1.jpg",
        "require_image": false,
    },
    // {
    //     "id": "lyingdown2",
    //     "name": "Missionary #2",
    //     "prompt": "1girl",
    //     "demo_image": "",
    //     "require_image": false,
    // },
    {
        "id": "lyingdown3",
        "name": "Missionary #3",
        "prompt": "aisan, long hair, white dress, on bed",
        "demo_image": "./image/workflow_missionary_3.jpg",
        "require_image": false,
    },
    // {
    //     "id": "openleg1",
    //     "name": "Spread Leg #1",
    //     "prompt": "1girl, leg spread, having sex",
    //     "demo_image": "",
    //     "require_image": false,
    // },
    // {
    //     "id": "grabass",
    //     "name": "Grab Ass",
    //     "prompt": "1girl, ass grabbed",
    //     "demo_image": "",
    //     "require_image": false,
    // },
    {
        "id": "sidesex",
        "name": "Get Fucked",
        "prompt": "1girl, leg spread, having sex",
        "demo_image": "./image/workflow_sidesex.jpg",
        "require_image": false,
    },
    // {
    //     "id": "doggy_controlnet_1",
    //     "name": "Doggy Style",
    //     "prompt": "1girl, having sex",
    //     "demo_image": "./image/workflow_doggy_controlnet1.jpg",
    //     "require_image": false,
    // },
    {
        "id": "splitleg_1",
        "name": "Open Your Legs",
        "prompt": "1girl, leg spread, having sex",
        "demo_image": "./image/workflow_split_leg.jpg",
        "require_image": false,
    }
]

export const InpaintingModels = [
    {
        "id": "sdxl",
        "name": "SDXL"
    },
    {
        "id": "sd15",
        "name": "SD1.5"
    }
]