import React, { useState, useEffect } from 'react';
// import '../../css/App.css'
import '../../css/Chat.css'
import { useParams } from 'react-router-dom';
import { HttpGet, HttpPost } from '../../http/HttpClient';

function DMChat() {
  const { dm_uuid } = useParams();
  const [digitalmodel, setDigitalmodel] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');

  const sendMessage = async () => {
    if (!inputMessage.trim()) {
      return;
    }

    const userMessage = inputMessage
    const payload = {
        "dm_uuid": dm_uuid,
        "message": userMessage
    }

    setMessages([...messages, { sender: 'You', text: userMessage }]);
    setInputMessage('');

    HttpPost("/digitalmodel/chat/openai/private", payload)
    .then((response) => {
        const result = response.data.data.result
        console.log(result)
        setMessages([...messages,  { sender: 'You', text: userMessage }, { sender: 'Response', text: result }]);
    })
    .catch((error)=>{
        console.log(error)
        setMessages([...messages,  { sender: 'You', text: userMessage }, { sender: 'Response', text: error }]);
    })

  };

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  useEffect(() => {

    const getDigitalModel = () => {
        HttpGet("/digitalmodel/model/get/" + dm_uuid)
        .then((response)=>{
            const dm = response.data.data.result
            console.log(dm)
            setDigitalmodel(dm)
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    getDigitalModel()
  }, []);

  return (
    <div className="App">
      <header className="App-header">
      </header>
      {
        digitalmodel !== null && (
            <div>
                <img className='profile-image' src={digitalmodel.image_url}/>{digitalmodel.name}
            </div>
        )
      }
    <div className="chat-container">
        {messages.map((msg, index) => {
            if (msg.sender==='You') {
                return (
                    <div key={index} className='chat-bubble right'>
                    {msg.text}
                    </div>
                )
            } 
            return (
                <div key={index} className='chat-bubble left'>
                {msg.text}
                </div>
            )
        }
        )}
      <div className="input-container">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type your message here..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>

    </div>

    </div>
  );
}

export default DMChat;
