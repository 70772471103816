import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {Host} from '../../../config';
import { UpdateUserPoints } from '../../../user/User';
import MultipleFaceWorkflowStep2 from './MultipleFaceWorkflowStep2';
import '../../../css/MultipleFaceWorkflow.css';
import T from '../../../language';
import ReactPlayer from 'react-player';

import { HttpPost, HttpGet, HttpPostForm } from '../../../http/HttpClient';

function MultipleFaceVideoWorkflow({ workflow, onCloseWorkflow, onComplete }) {
    const [srcImageFiles, setSrcImageFiles] = useState([]);
    const [targetImageFiles, setTargetImageFiles] = useState([]);
    const [srcImagePreviews, setSrcImagePreviews] = useState([]);
    const [targetImagePreviews, setTargetImagePreviews] = useState([]);
    const [message, setMessage] = useState('');
    const [step, setStep] = useState(1);
    const [taskUUID, setTaskUUID] = useState(null);
    const [faces, setFaces] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [targetVideoPreview, setTargetVideoPreview] = useState('');
    const [targetVideoFile, setTargetVideoFile] = useState(null);


    const close = () => {
        onCloseWorkflow();
    };

    const handleSrcImageChange = (e) => {
        const files = Array.from(e.target.files).slice(0, 5);
        setSrcImageFiles(files);

        const previews = files.map(file => URL.createObjectURL(file));
        setSrcImagePreviews(previews);
    };

    const handleTargetImageChange = (e) => {
        const files = Array.from(e.target.files).slice(0, 5);
        setTargetImageFiles(files);

        const previews = files.map(file => URL.createObjectURL(file));
        setTargetImagePreviews(previews);
    };

    const handleTargetVideoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check file size (limit to 100MB)
            if (file.size > 100 * 1024 * 1024) { // 100MB in bytes
                setMessage('Video file size must be less than 100MB.');
                return;
            }
            setTargetVideoFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setTargetVideoPreview(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };    

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (targetVideoFile === null || targetImageFiles.length === 0 || srcImageFiles.length === 0) {
            setMessage("something is missing");
            return;
        }

        const formData = new FormData();
        srcImageFiles.forEach((file, index) => {
            formData.append(`src_image_${index}`, file);
        });
        targetImageFiles.forEach((file, index) => {
            formData.append(`target_image_${index}`, file);
        });

        formData.append('target_video', targetVideoFile);

        HttpPostForm('/faceswap/task/multi_face_video', formData)
        .then((response) => {
            const resp = response.data;
            console.log(resp.data);
            if (resp.error) {
                setMessage(resp.error);
            } else {
                setMessage('SUCCESS');
                console.log(resp.data.task_uuid);
                setTaskUUID(resp.data.task_uuid);
                setStep(2)
                // Reset the component
                setSrcImageFiles([]);
                setTargetImageFiles([]);
                setSrcImagePreviews([]);
                setTargetImagePreviews([]);
                UpdateUserPoints();
            }    
        })
        .catch((error) => {
            console.error('Error uploading form data', error);
            setMessage('An error occurred while submitting the form.');                    
        })
    };

    useEffect(() => {
        if (taskUUID) {
            const interval = setInterval(async () => {
                try {
                    const token = Cookies.get("X-API-TOKEN");
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + token
                    };

                    const response = await axios.get(`${Host.Server}/faceswap/task/faces/${taskUUID}`, { headers });
                    console.log(response);
                    const resultData = response.data.data;
                    if (resultData.result) {
                        console.log(resultData.result);
                        setFaces(resultData.result)
                        clearInterval(interval);
                    }
                } catch (error) {
                    console.error('Error fetching task result', error);
                }
            }, 4000);
            return () => clearInterval(interval);
        }
    }, [taskUUID]);


    return (
        <div>
            {step === 1 && (
            <div className="workflow-container">
            <h2>{workflow.name}</h2>
            <h2>{T("Cost")} {workflow.cost}</h2>

            <div className="input-section">
                <div className="image-upload">
                    <label htmlFor="srcImageInput">Source Face Images (up to 5)</label>
                    <input id="srcImageInput" type="file" accept="image/*" multiple onChange={handleSrcImageChange} />
                    <div className="image-previews">
                        {srcImagePreviews.map((src, index) => (
                            <img key={index} src={src} alt={`Source Preview ${index + 1}`} />
                        ))}
                    </div>
                </div>
                <div className="image-upload">
                    <label htmlFor="targetImageInput">Target Images (up to 5)</label>
                    <input id="targetImageInput" type="file" accept="image/*" multiple onChange={handleTargetImageChange} />
                    <div className="image-previews">
                        {targetImagePreviews.map((src, index) => (
                            <img key={index} src={src} alt={`Target Preview ${index + 1}`} />
                        ))}
                    </div>
                </div>
                <div className="video-upload">
                    <label htmlFor="targetVideoInput">{T("Target Video")}</label>
                    <input id="targetVideoInput" type="file" accept=".mp4,.avi,.mov" onChange={handleTargetVideoChange} />
                    {targetVideoPreview && (
                        <ReactPlayer
                            url={targetVideoPreview}
                            controls
                            width="100%"
                            height="300px"
                        />
                    )}
                </div>

            </div>
            <div className="button-section">
                <button className="submit-button" onClick={handleSubmit}>Submit</button>
                <button className="back-button" onClick={close}>Back</button>
            </div>
            {message && <div className={`message ${message === 'SUCCESS' ? 'success' : 'error'}`}>{message}</div>}
            </div>
            )}
            {step === 2 && taskUUID && step===2 && !faces && (
                <div className="success-message">
                    <h2>SUCCESS</h2>
                    <div className="loading-spinner">
                        <p>Processing... Please wait.</p>
                        <div className="spinner"></div>
                        <p>{T('You can close and check the result in "Gallery"')}</p>
                        <button className="back-button" onClick={close}>Close</button>
                    </div>
                </div>                            
            )}
            {step === 2 && taskUUID && faces && 
            <MultipleFaceWorkflowStep2 taskUuid={taskUUID} faces={faces} onClose={onCloseWorkflow} onComplete={onComplete}/>}

        </div> 
    );
}

export default MultipleFaceVideoWorkflow;
