import React, { useState, useEffect } from 'react';
import MultipleFaceWorkflowStep2 from '../creator/MultipleFaceWorkflowStep2';
import '../../../css/FaceswapVideoGallery.css';
import { HttpGet } from '../../../http/HttpClient';

function FaceswapVideoGallery() {
    const [activeVideo, setActiveVideo] = useState(null);
    const [userVideos, setUserVideos] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(20); // Define page size here

    const openVideo = (video) => {
        setActiveVideo(video);
        document.body.style.overflow = 'hidden';
    };

    const closeVideo = () => {
        setActiveVideo(null);
        document.body.style.overflow = 'auto';
    };

    const closePending = () => {
        setActiveVideo(null);
        document.body.style.overflow = 'auto';
        fetchUserVideos();
    }

    const fetchUserVideos = async () => {
        HttpGet(`/faceswap/video/browse?page=${currentPage}&size=${pageSize}` )
        .then((response)=>{
            const result = response.data.data.result;
            console.log(result);
            setUserVideos(result);
        })
        .catch((error)=>{
            console.error('Error fetching user videos:', error);
        })
    };

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    useEffect(() => {
        fetchUserVideos();
    }, [currentPage]);

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };


    return (
        <div className="faceswap-gallery-container">
            <div className="video-grid">
                {userVideos.map((video, index) => (
                    <div key={index} className={`video-card ${userVideos.length % 2 === 1 && index === userVideos.length - 1 ? 'last-single' : ''}`}>
                        {video.video_url && video.video_url !== "" && video.status === undefined && (
                            <video src={video.video_url} controls onClick={() => openVideo(video)} />
                        )}
                        {(video.video_url === undefined || video.video_url === "") && video.status === "0" && (
                            <div className="image-card" onClick={() => openVideo(video)}>
                                <img src="./image/pending.jpg" alt="Loading" />
                            </div>
                        )}
                        {(video.video_url === undefined || video.video_url === "") && video.status === "1" && (
                            <div className="image-card" onClick={() => openVideo(video)}>
                                <img src="./image/loading-green.gif" alt="Loading" />
                            </div>
                        )}
                        {(video.video_url === undefined || video.video_url === "") && video.status === "4" && (
                            <div className="image-card" onClick={() => openVideo(video)}>
                                <img src="./image/task_needs_user_input.png" alt="Loading" />
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 0}>Previous</button>
                <button onClick={handleNextPage} disabled={userVideos.length < pageSize}>Next</button>
            </div>

            {activeVideo && (!activeVideo.faces || activeVideo.faces.length === 0) && (
                <div className="video-overlay" onClick={closeVideo}>
                    <div className="overlay-content">
                        <span>{activeVideo.task_uuid}</span>
                        <video src={activeVideo.video_url} controls autoPlay className="full-screen-video" />
                        <p>{activeVideo.prompt}</p>
                        <span>Model: {activeVideo.modelName}</span>
                    </div>
                </div>
            )}

            {activeVideo && activeVideo.faces && activeVideo.faces.length > 0 && (
                <div className="video-overlay">
                    <MultipleFaceWorkflowStep2 taskUuid={activeVideo.task_uuid} faces={JSON.parse(activeVideo.faces)} onClose={closePending} />
                </div>
            )}
        </div>
    );
}

export default FaceswapVideoGallery;
