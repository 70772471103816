import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Host, ImageModels } from '../../../config';
import { UpdateUserPoints } from '../../../user/User';
import '../../../css/ImageGeneration.css';
import T from '../../../language'
import { HttpPostForm, HttpGet } from '../../../http/HttpClient';

function CreatorForm({ service, onCloseCreatorForm }) {
  const [textAreaValue, setTextAreaValue] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [seedValue, setSeedValue] = useState(0);
  const [numOfImages, setNumOfImages] = useState(2);
  const [selectedModel, setSelectedModel] = useState(ImageModels[0].model_id); // Default to the first model
  const [hdVersion, setHdVersion] = useState(false);
  const [message, setMessage] = useState(null);
  const [taskUUID, setTaskUUID] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!ableToSubmit()) {
      return;
    }
    if (service.require_image === true && imageFile === null) {
      setMessage("Image file required");
      return;
    } 

    const formData = new FormData();
    formData.append("use_ip_adapter", service.use_ip_adapter);
    formData.append('prompt', textAreaValue);
    formData.append('seed', seedValue);
    formData.append('image_number', numOfImages);
    formData.append('model_id', selectedModel); // Use selected model_id
    formData.append('hd_enabled', hdVersion);
    if (imageFile) {
      formData.append('file', imageFile);
    }

    localStorage.setItem("last_prompt", textAreaValue);
    localStorage.setItem("last_model_id", selectedModel);
    localStorage.setItem("last_use_hd_version", hdVersion ? "true" : "false");
    localStorage.setItem("last_seed", seedValue+"");

    try {
      const token = Cookies.get("X-API-TOKEN");
      HttpPostForm('/gallery/task/add', formData)
        .then((response) => {
          const resp = response.data;
          console.log(resp.data);
          if (resp.error) {
              setMessage(resp.error);
          } else if (resp.data) {
              setMessage('Pending processing...');
              console.log(resp.data.task_uuid)
              setTaskUUID(resp.data.task_uuid);
              // Clear the state
              setTextAreaValue('');
              setImageFile(null);
              setImagePreview('');
              UpdateUserPoints();
          }
        })
        .catch((err) => {
          setMessage(err.response.data.error);
          console.log(err.response);
        });
      setMessage('Submitting...');
    } catch (error) {
      console.error('Error uploading form data', error);
      setMessage('Error submitting the form');
    }
  };

  const closeForm = () => {
    onCloseCreatorForm();
  };

  const ableToSubmit = () => {
    return message == null;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMessage(null);
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = (img) => {
    setSelectedImage(img);
  };

  const handleOverlayClick = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    if (taskUUID) {
        const interval = setInterval(async () => {
            try {
                HttpGet(`${Host.Server}/gallery/task/result/${taskUUID}`)
                .then((response)=>{
                  console.log(response);
                  const resultData = response.data.data;
                  if (resultData.result && resultData.result.length > 0) {
                      console.log(resultData.result);
                      setImages(resultData.result);
                      clearInterval(interval);
                  }  
                })
                .catch((error)=>{
                  console.error('Error fetching task result', error);
                })
            } catch (error) {
                console.error('Error fetching task result', error);
            }
        }, 4000);
        return () => clearInterval(interval);
    } else {
      var v = localStorage.getItem("last_prompt")
      if (v !== null) {
        setTextAreaValue(v);
      }
      v = localStorage.getItem("last_model_id");
      if (v !== null) {
        setSelectedModel(v);
      }
  
      v = localStorage.getItem("last_use_hd_version");
      if (v !== null && v==='true') {
        setHdVersion(true)
      }
  
      v = localStorage.getItem("last_seed");
      if (v !== null) {
        setSeedValue(parseInt(v))
      }  
    }

  }, [taskUUID]);

  return (
    <div>
      {!taskUUID && (
        <div className="generation-container">
          <label>{service.name}</label>
          <div className="input-container">
            <label htmlFor="modelSelect">Select Model:</label>
            <select
              id="modelSelect"
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
            >
              {ImageModels.map((model) => (
                <option key={model.model_id} value={model.model_id}>
                  {model.name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-container">
            <label htmlFor="textarea">What do you want to draw:</label>
            <textarea id="textarea" value={textAreaValue} onChange={(e) => setTextAreaValue(e.target.value)} />
          </div>
          <div className="preview-container">
            <label htmlFor="imageInput">Upload Image:</label>
            <input id="imageInput" type="file" accept="image/*" onChange={handleFileChange} />
            {imagePreview && <img src={imagePreview} alt="Preview" />}
          </div>
              
          <div className="input-container">
            <label htmlFor="seedValue">Seed:</label>
            <input type="text" id="seedValue" value={seedValue} onChange={(e) => setSeedValue(e.target.value)} />
          </div>
          <div className="input-container">
            <div className="horizontal">
              <label htmlFor="hdVersion" style={{ marginRight: '10px' }}>HD Version:</label>
              <input
                type="checkbox"
                id="hdVersion"
                checked={hdVersion}
                onChange={(e) => {setHdVersion(e.target.checked);}}
              />
            </div>
          </div>

          <div className="input-container">
            <label htmlFor="numOfImagesDropDown">Number of Images:</label>
            {service.cost}
            <select
              id="numOfImagesDropDown"
              value={numOfImages}
              onChange={(e) => setNumOfImages(e.target.value)}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              {!hdVersion && <option value="4">4</option>}
            </select>
          </div>
          <div className="button-container">
            <button onClick={(e) => handleSubmit(e)}>Submit</button>
            <button onClick={(e) => closeForm()}>Back</button>
          </div>
          {message && <div className={`message ${message === 'SUCCESS' ? 'success' : 'error'}`}>{message}</div>}
        </div>
      )}
      {taskUUID && images.length === 0 && (
        <div className="loading-spinner" onClick={closeForm}>
          <p>Generating... Please wait.</p>
          <div className="spinner"></div>
          <p>{T('You can close and check the result in "Gallery"')}</p>
          <button onClick={(e) => {
            e.preventDefault();
            closeForm();
            }}>Close</button>
        </div>
      )}
      {taskUUID && images.length > 0 && (
        <div className="success-message">
          <h2>SUCCESS</h2>
          <div className="image-grid">
            {images.map((img, index) => (
              <img key={index} src={img.url} alt={`Result ${index}`} onClick={() => handleImageClick(img)} />
            ))}
          </div>
          <button onClick={(e) => {
            e.preventDefault();
            closeForm();
            }}>Close</button>
        </div>
      )}
      {selectedImage && (
        <div className="overlay" onClick={handleOverlayClick}>
          <img src={selectedImage.url} alt="Full Screen" className="full-screen-image" />
        </div>
      )}
    </div>
  );
}

export default CreatorForm;
