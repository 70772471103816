import React, { useState } from 'react';
import { InpaintingModels } from '../../config';
import ImageMasker from './ImageMasker';
import '../../css/ImageEditor.css';
import { HttpPost } from '../../http/HttpClient';

const ImageEditor = ({ imageUrl, imageBucket, objectName, onClose }) => {
  const [mask, setMask] = useState('');
  const [prompt, setPrompt] = useState('');
  const [selectedModelId, setSelectedModelId] = useState(InpaintingModels[0].id);
  const [seed, setSeed] = useState('0');
  const [strength, setStrength] = useState('1.0');
  const [message, setMessage] = useState('');

  const handleMaskSave = (maskData) => {
    setMask(maskData);
  };

  const handleSubmit = async () => {
    if (!mask) {
      setMessage('Please draw a mask.');
      return;
    }

    if (!prompt) {
      setMessage('Please enter a prompt.');
      return;
    }

    const data = {
      bucket: imageBucket,
      object_name: objectName,
      mask_data: mask,
      model_id: selectedModelId,
      prompt: prompt,
      seed: seed,
      strength: strength
    };

    HttpPost("/gallery/task/inpainting/add", data)
    .then((response) => {
        setMessage("in processing")
    })
    .catch((error) => {
        setMessage('Error submitting the task.');
        console.error(error);
    })
  };

  return (
    <div className="image-editor-container">
      <div className="image-masker-container">
        <ImageMasker
          imageSrc={imageUrl}
          onMaskSave={handleMaskSave}
        />
      </div>
      <div className="input-container">
        <label htmlFor="prompt">Prompt:</label>
        <textarea
          id="prompt"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label htmlFor="modelSelect">Model:</label>
        <select
          id="modelSelect"
          value={selectedModelId}
          onChange={(e) => setSelectedModelId(e.target.value)}
        >
          {InpaintingModels.map((model) => (
            <option key={model.id} value={model.id}>
              {model.name}
            </option>
          ))}
        </select>
      </div>
      <div className="input-container">
        <label htmlFor="seed">Seed:</label>
        <input
          type="text"
          id="seed"
          value={seed}
          onChange={(e) => setSeed(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label htmlFor="strength">Strength:</label>
        <input
          type="text"
          id="strength"
          value={strength}
          onChange={(e) => setStrength(e.target.value)}
        />
      </div>
      {message && <div className="message">{message}</div>}
      <div className="button-container">
        <button onClick={handleSubmit}>Submit</button>
        <button onClick={(e)=>onClose()}>Close</button>
      </div>
    </div>
  );
};

export default ImageEditor;
