import { HttpGet } from "../../http/HttpClient";
import Cookies from 'js-cookie';

export const decodeToJson = (data) => {
  const binaryString = atob(data)

  const binaryLength = binaryString.length;
  const bytes = new Uint8Array(binaryLength);
  for (let i = 0; i < binaryLength; i++) {
      bytes[i] = binaryString.charCodeAt(i);
  }

  const decodedString = new TextDecoder().decode(bytes);
  const model = JSON.parse(decodedString)
  return model
}

export const encodeJson = (data) => {
  var value = JSON.stringify(data)
  const utf8Array = new TextEncoder().encode(value);
  const base64String = btoa(String.fromCharCode(...utf8Array));
  return encodeURIComponent(base64String);
}

export const isLoggedIn = () => {
  var token = Cookies.get("X-API-TOKEN");
  return token !== undefined;
}

export const imageDataURLtoFile = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const filename = "user_image.jpg";

    return new File([u8arr], filename, { type: mime });
  };

export const getCachedMembership = async () => {
  var v = localStorage.getItem("membership");
  if (v === undefined || v === null) {
    try {
      var response = await HttpGet("/user/membership");
      var result = response.data.data.result;
      console.log("membership: " + result);
      localStorage.setItem("membership", result + "");
      v = result;
    } catch (error) {
      console.log(error)
    }
  }
  return v;
}

export const convertToLocalDateTime = (timestamp) => {
  // Create a Date object from the timestamp string
  const date = new Date(timestamp);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  // Get the local datetime string
  const localDateTime = date.toLocaleString();

  return localDateTime;
};
