import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import '../css/App.css';
import { UserPointEventEmitter, PaymentMenuEventEmitter } from '../event/event.js';


function UserInfoSection({ onUsernameClick, onUserPointsClick }) {
    const [userPoints, setUserPoints] = useState(0);
    const [showPayment, setShowPayment] = useState(false)
  
    const getUsername = () => {
      var username = Cookies.get("X-USERNAME");
      if (username === 'undefined') {
        return "";
      }
      return username;
    }
  
    useEffect(() => {
      const handleUpdatePoints = (newPoints) => {
        setUserPoints(newPoints);
      };
  
      // Listen for the 'updatePoints' event
      UserPointEventEmitter.on('updatePoints', handleUpdatePoints);
  
      // Clean up the event listener on component unmount
      return () => {
        UserPointEventEmitter.off('updatePoints', handleUpdatePoints);
      };
    }, []);
  
    return (
      <div className="user-info">
        <span className="username" onClick={onUsernameClick}>{getUsername()}</span>
        <span className="points" onClick={onUserPointsClick}>⭐ {userPoints}</span>
      </div>
    );
  }

  export default UserInfoSection