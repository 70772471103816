import React, { useState, useRef } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Items } from "./Items";
import { HttpPost } from "../../http/HttpClient";
import '../../css/PaypalPayment.css'; // Import the CSS file
import T from "../../language";
const PaypalPayment = ( {onClose} ) => {
  const [amount, setAmount] = useState("10.00");
  const [message, setMessage] = useState(null);
  const selectedItemId = useRef("1")

  const handleCreateOrder = (data, actions) => {
    var selectedId = parseInt(selectedItemId.current)
    if (selectedId === 0 || Items[selectedId] === undefined) {
      setMessage("invalid item");
      return Promise.reject("invalid item");
    }

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: Items[selectedId].cost,
          },
        },
      ],
    });
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    selectedItemId.current = e.target.value
  };

  return (
    <div className="paypal-container">
      <h1 className="paypal-title">{T("Membership & Points")}</h1>

      {message && <div className="paypal-message">{message}</div>}      
      <div className="paypal-dropdown-container">
        <label htmlFor="amount" className="paypal-label">{T("Select Amount (USD)")}</label>
        <select
          id="amount"
          value={amount}
          onChange={handleAmountChange}
          className="paypal-dropdown"
        >
          {Object.entries(Items).map(([key, item]) => (
            <option key={key} value={key}>
              ${item.cost.toFixed(2)} - ({T(item.description)})
            </option>
          ))}
        </select>
      </div>
      <PayPalScriptProvider options={{ "client-id": "AVPQZloOkdzV8tFe8fXFyHD5i4L-3ZgluwSaVYT4SWLG3oR33dWIQV2Yfb_YI8twm4bHhgjFeQEcRtDK", "currency": "USD" }}>
        <PayPalButtons
          style={{ layout: "vertical" }}
          createOrder={handleCreateOrder}
          onApprove={(data, actions) => {
            return actions.order.capture().then(async (details) => {
            //   alert(`Transaction completed by ${details.payer.name.given_name}`);
              setMessage("Verifying Order Status")
              console.log(details);
              var purchase = details.purchase_units[0];
              if (!purchase) {
                setMessage("Missing purchase");
                return;
              }
              var orderStatus = details.status;
              if (orderStatus !== "COMPLETED") {
                setMessage("Order Status not completed: " + orderStatus);
                return;
              }
              
              var order = {
                "order_id": details.id,
                "amount": purchase.amount.value,
                "currency": purchase.amount.currency_code,
                "item_id": selectedItemId.current
              };
              HttpPost('/payment/paypal/order/verify', order)
              .then((response) => {
                const resp = response.data;
                console.log(resp.data);
                if (resp.error) {
                  setMessage(resp.error);
                } else {
                    setMessage('SUCCESS');
                }
              })
              .catch((error) => {
                console.error('Error uploading form data', error);
                setMessage('An error occurred while submitting the form.');                
              })
            });
          }}
          onError={(err) => {
            console.error("PayPal Checkout onError", err);
            alert("An error occurred during the transaction");
          }}
        />
      </PayPalScriptProvider>
      {onClose && (
        <button onClick={onClose}>{T("Close")}</button>
      )}
    </div>
  );
};

export default PaypalPayment;
