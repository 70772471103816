import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Host } from '../../config';
import { HttpPost, HttpPostFormWithoutAuth, HttpPostWithTestToken, HttpPostWithoutToken } from '../../http/HttpClient';
import T from '../../language';
import '../../css/Aireply.css'

function AiReply() {
    const [srcImageFile, setSrcImageFile] = useState(null);
    const [srcImagePreview, setSrcImagePreview] = useState('');
    const [message, setMessage] = useState('');
    const [answers, setAnswers] = useState([])
    const [loading, setLoading] = useState(false)

    const handleSrcImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSrcImageFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setSrcImagePreview(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('image_file', srcImageFile);
        
        try {
            setLoading(true)
            HttpPostFormWithoutAuth('/tool/image/upload', formData)
            .then((response) =>{
                const resp = response.data;
                console.log(resp.data);
                if (resp.error) {
                    setMessage(resp.error);
                } else {
                    const imageUrl = resp.data.image_url;
                    console.log(imageUrl);

                    axios.post("https://likexx--aireply-demo-chat.modal.run", {"image_url": imageUrl}, {
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer abcd1234'
                        },
                      })
                    .then((response)=>{
                        console.log(response);
                        setAnswers(response.data.result)
                        setLoading(false)
                    })
                    .catch((error)=>{
                        console.log(error);
                    })
                }    
            })
            .catch ((error) => {
                console.error('Error uploading form data', error);
                setMessage('An error occurred while submitting the form.');
            })
        } catch (error) {
        }
    };

    return (
        <div className="workflow-container">
            <div>
                <h2>{"如何回答"}</h2>
                <div className="input-section">
                    <div className="image-upload">
                        <label htmlFor="srcImageInput">{"选择一张聊天截图"}</label>
                        <input id="srcImageInput" type="file" accept="image/*" onChange={handleSrcImageChange} />
                        {srcImagePreview && <img src={srcImagePreview} alt="Source Preview" />}
                    </div>
                </div>
                <div className="button-section">
                    <button className="submit-button" onClick={handleSubmit}>{T("提交")}</button>
                </div>

                {!loading && (
                    <>
                        <span>推荐回答</span>
                        {answers.map((answer, index) => {
                            console.log(answer);
                            return <div key={index}>{answer}</div>;
                        })}
                    </>
                )}

                {loading && (
                    <div className="loading-spinner">
                        <p>{"AI思考中"}</p>
                        <div className="spinner"></div>
                    </div>
                )}

                {message && <div className={`message ${message === 'SUCCESS' ? 'success' : 'error'}`}>{message}</div>}
            </div>
        </div>
    );
}

export default AiReply;
