import React, { useState, useEffect } from 'react';
import '../../../css/Gallery.css';
import T from '../../../language';
import { HttpPost, HttpGet } from '../../../http/HttpClient';
// import ImageMasker from '../../common/ImageMasker';
import ImageEditor from '../../common/ImageEditor';
import { useParams } from 'react-router-dom';
import { decodeToJson, encodeJson } from '../../common/util';
import { Link } from 'react-router-dom';
import PhotoGalleryPicker from './PhotoGalleryPicker';


function DigitalModelEditor() {
    const { digitalmodel_data } = useParams();
    const [digitalModel, setDigitalModel] = useState(decodeToJson(digitalmodel_data));
    const [activeImage, setActiveImage] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [originalProfilePhoto, setOriginalProfilePhoto] = useState(null);
    const [selectedProfilePhoto, setSelectedProfielPhoto] = useState(null)
    const [tasks, setTasks] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(10); // Define page size here
    const [isEditMode, setIsEditMode] = useState(false)
    const [selectedPhotos, setSelectedPhotos] = useState([])
    const [showPhotoGallery, setShowPhotoGallery] = useState(false)

    const [description, setDescription] = useState("")
    const [name, setName] = useState("")
    const [frequencyHours, setFrequencyHours] = useState("24")

    const openImage = (img) => {
        setActiveImage(img);
        document.body.style.overflow = 'hidden';
    };

    const closeImage = () => {
        if (!isEditMode) {
            setActiveImage(null);
        }
        document.body.style.overflow = 'auto';
    };

    useEffect(() => {
        return () => {
            setName(digitalModel.name)
            setDescription(digitalModel.description)
            setFrequencyHours("" + Math.round(digitalModel.activity_frequency/60))
            document.body.style.overflow = 'auto';
        };
    }, []);

    const onSelectPhoto = (bucket, object) => {
        const key = bucket + ":"+ object
        var removed = false
        for (var i = 0; i < selectedPhotos.length; i++) {
            const p = selectedPhotos[i]
            if (p["bucket"]===bucket && p["object"]===object) {
                selectedPhotos.splice(i, 1)
                document.getElementById(bucket+":"+object).checked = false
                removed = true
                break
            }
        }
        if (!removed) {
            if (selectedPhotos.length >= 9) {
                alert("you cannot select more than 9 photos")
                return
            }
            selectedPhotos.push({
                "bucket": bucket,
                "object": object
            })
            document.getElementById(bucket+":"+object).checked = true
        }

        setSelectedPhotos(selectedPhotos)
    }

    const fetchPhotos = async (page) => {
        HttpGet(`/digitalmodel/photo/list/${digitalModel.uuid}/${currentPage}/${pageSize}`)
        .then((resp)=>{
            var result = resp.data.data.result
            console.log(result)
            var photos = {}
            for (var i = 0; i<result.length; i++) {
                var r = result[i]
                if (r.task_uuid === undefined) {
                    if (r.is_original === "true") {
                        setOriginalProfilePhoto(r)
                    }  else {
                        setProfilePhoto(r)
                        setSelectedProfielPhoto(r)
                    }
                } else {
                    if (photos[r.task_uuid]===undefined) {
                        photos[r.task_uuid]=[r]
                    } else {
                        photos[r.task_uuid].push(r)
                    }
                }
            }
            var ts = []
            Object.keys(photos).forEach((value, index)=>{
                var p = photos[value]
                ts.push(p)
            })
            console.log(ts)            
            setTasks(ts)
        })
        .catch((err)=>{
            console.log(err)
        })
    };

    useEffect(() => {
        fetchPhotos(currentPage);
    }, [currentPage]);

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const postToFeed = (photo) => {
        HttpPost("/feed/add_by_user", {
            "task_uuid": photo.task_uuid,
            "text": photo.task_text,
            "dm_uuid": digitalModel.uuid,
            "photos": selectedPhotos,
        })
        .then((response) => {
            console.log(response)
            fetchPhotos(currentPage)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const createNewPhoto = () => {
        activeImage.uuid = digitalModel.uuid
        window.location.href="/digitalmodel/photo/create/" + encodeJson(activeImage)
    };

    const showAllPhotoGallery = () => {
        setShowPhotoGallery(true)
    }


    const convertToLocalDateTime = (timestamp) => {
        // Create a Date object from the timestamp string
        const date = new Date(timestamp);
      
        // Check if the date is valid
        if (isNaN(date.getTime())) {
          return 'Invalid Date';
        }
      
        // Get the local datetime string
        const localDateTime = date.toLocaleString();
      
        return localDateTime;
      };

    const updateDigitalModel = () => {
        const payload = {
            'dm_uuid': digitalModel.uuid,
            "name": name,
            "bucket": selectedProfilePhoto.bucket,
            "object": selectedProfilePhoto.object,
            "description": description,
            "activity_frequency": parseInt(frequencyHours, 10)*60
        }
        console.log(payload)
        HttpPost("/digitalmodel/model/update", payload)
        .then((response) => {
            const data = response.data.data
            if (data.OpenAssistantId !== undefined) {
                digitalModel.openai_assistant_id = data.OpenAssistantId
            }
            digitalModel.uuid = data.Uuid
            digitalModel.activity_frequency = data.ActivityFrequency
            digitalModel.bucket = data.Bucket
            digitalModel.object = data.Object
            digitalModel.name = data.Name
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return (
        <div className="gallery-container">
            <h2>{T("Pick a Photo")}</h2>
            <div className="input-container">
            <span style={{ color: "white" }}>Name</span>
            <input type="text" value={name} onChange={(e)=>setName(e.target.value)} />
            <label htmlFor="textarea">Instruction for AI</label>
            <textarea id="textarea" value={description} onChange={(e) => setDescription(e.target.value)} />
            <span style={{ color: "white" }}>Assistant Id: {digitalModel.openai_assistant_id}</span>
            <span style={{ color: "white" }}>Frequency: 
                <input type="text" value={frequencyHours} onChange={(e)=>setFrequencyHours(e.target.value)}/>
            Hours</span>
            </div>
            <div className='image-grid-3'>
            {
                selectedProfilePhoto != null && (
                    <div className="image-card">
                        Selected Profile Photo
                        <img src={selectedProfilePhoto.img_url} alt="" onClick={() => openImage(selectedProfilePhoto)}/>
                    </div>    
                )
            }

            {
                profilePhoto != null && (
                    <div className="image-card">
                        Current Profile Photo
                        <img src={profilePhoto.img_url} alt="" onClick={() => openImage(profilePhoto)}/>
                        {
                            (profilePhoto.bucket != selectedProfilePhoto.bucket || profilePhoto.object != selectedProfilePhoto.object) && (
                                <button onClick={(e)=>{
                                    setSelectedProfielPhoto(profilePhoto)
                                }}>{T("Use for Profile")}</button>
                            )
                        }

                    </div>    
                )
            }

            {
                originalProfilePhoto != null && (
                    <div className="image-card">
                        Original Profile Photo
                        <img src={originalProfilePhoto.img_url} alt="" onClick={() => openImage(originalProfilePhoto)}/>
                        {
                            (originalProfilePhoto.bucket != selectedProfilePhoto.bucket || originalProfilePhoto.object != selectedProfilePhoto.object) && (
                                    <button onClick={(e)=>{
                                        setSelectedProfielPhoto(originalProfilePhoto)
                                    }}>{T("Use for Profile")}</button>
                            )
                        }
                    </div>    
                )
            }
            </div>
            <button onClick={(e)=>{updateDigitalModel()}}>Update</button>

            {tasks.map((photos, index) => (
                <div>
                    <div>{photos[0].task_text}</div>
                    <div>{photos[0].task_created_at}</div>
                    {
                        photos[0].released === "false" && photos[0].task_uuid !== undefined && photos[0].task_uuid !== "" && (
                        <div>
                            <button onClick={(e)=>{
                                postToFeed(photos[0])
                            }}>{T("Release")}</button>
                        </div>                                
                        )
                    }

                    <div className='image-grid'>
                    {
                        photos.map((photo, j)=>
                            (
                                <div className="image-card" key={j}>
                                <img src={photo.img_url} alt="" onClick={() => openImage(photo)}/>
                                <input
                                    id={photo.bucket + ":" + photo.object}
                                    type="checkbox"
                                    onChange={(e)=>{
                                        onSelectPhoto(photo.bucket, photo.object)
                                    }}
                                />
                                {
                                    (photo.bucket != selectedProfilePhoto.bucket || photo.object != selectedProfilePhoto.object) && (
                                        <button onClick={(e)=>{
                                            setSelectedProfielPhoto(photo)
                                        }}>{T("Use for Profile")}</button>
                                    )
                                }
                                </div>
                            )    
                        )
                    }
                    {
                        photos[0].released === "false" && (
                            <div className="image-card">
                            <button onClick={(e)=>{
                                setShowPhotoGallery(true)
                            }}>+</button>
                        </div>
                        )
                    }
                    </div>
                </div>
                )
                )
            }

            {/* <div className="image-grid">
            </div> */}

            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 0}>Previous</button>
                <button onClick={handleNextPage} disabled={tasks.length == 0}>Next</button>
            </div>


            {activeImage && !isEditMode && (
                <div className="image-overlay" onClick={closeImage}>
                    {activeImage.img_url !== "" && (
                        <div className="overlay-content">
                            <img src={activeImage.img_url} alt="Zoomed"/>
                            <button onClick={()=>{}} className="delete-button">{T("DISABLE")}</button>

                            <button onClick={()=>{createNewPhoto()}} className="delete-button">{T("Create")}</button>

                        </div>
                    )}
                </div>
            )}

            {activeImage && isEditMode && activeImage.img_url !== "" && (
                <div className="image-overlay">
                    <div className="overlay-content">
                    <ImageEditor
                        imageUrl={activeImage.img_url}
                        imageBucket={activeImage.bucket}
                        objectName={activeImage.object}
                        onClose={()=>{
                            setActiveImage(null);
                            setIsEditMode(false);
                            document.body.style.overflow = 'auto';
                        }}
                    />
                    </div>
                </div>                
            )}

            {
                showPhotoGallery && (
                    <div className="image-overlay">
                    <div className="overlay-content">

                    <PhotoGalleryPicker onSelectPhoto={(bucket, object)=>{
                        onSelectPhoto(bucket, object)
                    }} onClose={()=>{
                        setShowPhotoGallery(false)
                    }} getSelected={()=>{
                        return selectedPhotos.length
                    }}/>
                    </div>
                    </div>
                )
            }

        </div>
    );
}

export default DigitalModelEditor;
