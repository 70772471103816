import React, { useState, useEffect } from 'react';
import LoginSection from './user/Login.js';
import RegisterSection from './user/Register.js';
import './css/App.css';
import './css/HomeSection.css';
import T from './language.js';
import { HomepageFeatureList } from './config.js';

function HomeSection({ onLogin, showLoginSection, showRegisterSection, inviteCode }) {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    setIsLogin(showLoginSection);
  }, []);

  return (
    <div>
      <h2 className="slogan">{T("Truly Fun, Simple, Unrestricted AI Visuals for Everyone")}</h2>
      {
        !showLoginSection && !showRegisterSection && (
          <div className="home-section">
          {HomepageFeatureList.map((feature, index) => (
            <div className={`feature-row ${index % 2 === 0 ? 'text-left' : 'text-right'}`} key={index}>
                  <div className="feature-title">
                    {feature.title}
                    <div className='feature-text'>{feature.text}</div>
                  </div>
                  <div className="feature-image">
                    {
                      feature.image !== undefined && feature.image !== "" && (
                        <img src={feature.image} alt={feature.text} />
                      )
                    }
                    {
                      feature.video !== undefined && feature.video !== "" && (
                        <video width="512" height="512" autoPlay muted controls loop>
                          <source src={feature.video} type="video/mp4"/>
                        </video>
                      )
                    }
                  </div>
            </div>
          ))}
        </div>    
        )
      }
      {
        isLogin && (
          <div className="auth-container">
            <LoginSection onLogin={onLogin} setIsLogin={setIsLogin} />
          </div>  
        )
      }
      {
        !isLogin && showRegisterSection && (
        <div className="auth-container">
          <RegisterSection setIsLogin={setIsLogin} inviteCode={inviteCode} />
        </div>  
        )
      }

    </div>
  );
}

export default HomeSection;
