import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ImageModels, Host, AigcWorkflows } from '../../../config';
import '../../../css/Gallery.css';
import T from '../../../language';
import { HttpPost, HttpGet } from '../../../http/HttpClient';
// import ImageMasker from '../../common/ImageMasker';
import ImageEditor from '../../common/ImageEditor';

function PhotoGalleryPicker({onSelectPhoto, onClose, getSelected}) {
    const [activeImage, setActiveImage] = useState(null);
    const [userImages, setUserImages] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(10); // Define page size here
    const [countKey, setCountKey] = useState(1);

    const openImage = (img) => {
        setActiveImage(img);
        document.body.style.overflow = 'hidden';
        console.log(activeImage)
    };

    const closeImage = () => {
        setActiveImage(null);
        document.body.style.overflow = 'auto';
    };

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    const fetchUserImages = async (page) => {
        const url = `/gallery/browse?start_page=${page}&page_size=${pageSize}`;
        HttpGet(url)
        .then((response) => {
            const result = response.data.data.result;
            console.log(result);
            var images = []
            result.forEach((img) => {
                if (img.status==="2" || img.status === undefined) {
                    images.push(img)
                }
            });           
            setUserImages(images);    
        })
        .catch((error)=>{
            console.log(error)
        })
    };

    useEffect(() => {
        fetchUserImages(currentPage);
    }, [currentPage]);

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    return (
        <div className="gallery-container">
            <span style={{ color: "black" }} key={countKey}>selected: {getSelected()}</span>
            <button onClick={(e)=>{onClose()}}>Close</button>
            <div className="image-grid">
                {userImages.map((img, index) => (
                    <div className="image-card" key={index}>
                        {img.img_url !== "" && img.img_url !== undefined && <img src={img.img_url} alt="" onClick={() => openImage(img)}/>}
                        <input
                                id={img.bucket + ":" + img.object}
                                type="checkbox"
                                onChange={(e)=>{
                                    onSelectPhoto(img.bucket, img.object)
                                    setCountKey(countKey+1)
                                }}
                            />
                    </div>
                ))}
            </div>

            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 0}>Previous</button>
                <button onClick={handleNextPage} disabled={userImages.length == 0}>Next</button>
            </div>

            {activeImage && (activeImage.status==="2" || activeImage.status===undefined) && (
                <div className="image-overlay" onClick={closeImage}>
                    {activeImage.img_url !== "" && (
                        <div className="overlay-content">
                            <img src={activeImage.img_url} alt="Zoomed"/>
                            <p style={{ color: "black" }}>Prompt: {activeImage.prompt}</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default PhotoGalleryPicker;
