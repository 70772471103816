import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Host, ImageModels, AigcWorkflows } from '../../../config';
import { UpdateUserPoints } from '../../../user/User';
import '../../../css/ImageGeneration.css';
import T from '../../../language'
import { HttpGet, HttpPostForm } from '../../../http/HttpClient';

function AigcWorkflowCreatorForm({ service, onCloseCreatorForm }) {
  const [textAreaValue, setTextAreaValue] = useState(AigcWorkflows[0].prompt);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [prevewImage, setPreviewImage] = useState(AigcWorkflows[0].demo_image);

  const [seedValue, setSeedValue] = useState(0);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState(AigcWorkflows[0].id); // Default to the first model
  const [message, setMessage] = useState(null);
  const [taskUUID, setTaskUUID] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!ableToSubmit()) {
      return;
    }
    var workflow = getWorkflow(selectedWorkflowId)
    if (workflow == null) {
      setMessage("invalid selection");
      return
    }
    if (workflow.require_image === true && imageFile === null) {
      setMessage("Image file required");
      return;
    } 

    const formData = new FormData();
    formData.append('prompt', textAreaValue);
    formData.append('seed', seedValue);
    formData.append('workflow_id', selectedWorkflowId); // Use selected model_id
    if (imageFile) {
      formData.append('file', imageFile);
    }

    try {
      const token = Cookies.get("X-API-TOKEN");
      HttpPostForm('/gallery/task/workflow/add', formData)
        .then((response) => {
          const resp = response.data;
          console.log(resp.data);
          if (resp.error) {
              setMessage(resp.error);
          } else if (resp.data) {
              setMessage('Pending processing...');
              console.log(resp.data.task_uuid)
              setTaskUUID(resp.data.task_uuid);
              // Clear the state
              setTextAreaValue('');
              setImageFile(null);
              setImagePreview('');
              UpdateUserPoints();
          }
        })
        .catch((err) => {
          setMessage(err.response.data.error);
          console.log(err.response);
        });
      setMessage('Submitting...');
    } catch (error) {
      console.error('Error uploading form data', error);
      setMessage('Error submitting the form');
    }
  };

  const closeForm = () => {
    onCloseCreatorForm();
  };

  const ableToSubmit = () => {
    return message == null;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMessage(null);
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getWorkflow = (id) => {
    var workflow = null
    for (const wf of AigcWorkflows) {
      if (wf.id === id) {
        workflow = wf
        break
      }
    }
    return workflow
  }

  const handleImageClick = (img) => {
    setSelectedImage(img);
  };

  const handleOverlayClick = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    if (taskUUID) {
        const interval = setInterval(async () => {
          HttpGet(`${Host.Server}/gallery/task/result/${taskUUID}`)
          .then((response) => {
            console.log(response);
            const resultData = response.data.data;
            if (resultData.result && resultData.result.length > 0) {
                console.log(resultData.result);
                setImages(resultData.result);
                clearInterval(interval);
            }
          })
          .catch((error)=> {
            console.error('Error fetching task result', error);
          })
        }, 4000);
        return () => clearInterval(interval);
    }
  }, [taskUUID]);

  return (
    <div>
      {!taskUUID && (
        <div className="generation-container">
          <label>{service.name}</label>
          <div className="input-container">
            <label htmlFor="workflowSelect">{T("Select Scene:")}</label>
            <select
              id="workflowSelect"
              value={selectedWorkflowId}
              onChange={(e) => {
                setSelectedWorkflowId(e.target.value)
                var wf = getWorkflow(e.target.value)
                setTextAreaValue(wf.prompt)
                setPreviewImage(wf.demo_image)
              }}
            >
              {AigcWorkflows.map((workflow) => (
                <option key={workflow.id} value={workflow.id}>
                  {workflow.name}
                </option>
              ))}
            </select>
          </div>
          
          <div className="row">
          <div className="preview-container">
              {prevewImage && <img src={prevewImage} alt="Preview" />}
          </div>

          <div className="input-container">
            {/* <label htmlFor="textarea">{T("What do you want to draw:")}</label> */}
            <textarea id="textarea" value={textAreaValue} onChange={(e) => setTextAreaValue(e.target.value)} />
          </div>
          </div>
          {
            selectedWorkflowId && getWorkflow(selectedWorkflowId).require_image && (
              <div className="preview-container">
              <label htmlFor="imageInput">{T("Upload Image:")}</label>
              <input id="imageInput" type="file" accept="image/*" onChange={handleFileChange} />
              {imagePreview && <img src={imagePreview} alt="Preview" />}
              </div>
            )
          }
              
          <div className="input-container">
            <label htmlFor="seedValue">{T("Seed:")}</label>
            <input type="text" id="seedValue" value={seedValue} onChange={(e) => setSeedValue(e.target.value)} />
          </div>

          <div className="button-container">
            <button onClick={(e) => handleSubmit(e)}>Submit</button>
            <button onClick={(e) => closeForm()}>Back</button>
          </div>
          {message && <div className={`message ${message === 'SUCCESS' ? 'success' : 'error'}`}>{message}</div>}
        </div>
      )}
      {taskUUID && images.length === 0 && (
        <div className="loading-spinner" onClick={closeForm}>
          <p>Generating... Please wait.</p>
          <div className="spinner"></div>
          <p>{T('You can close and check the result in "Gallery"')}</p>
          <button onClick={(e) => closeForm()}>Close</button>
        </div>
      )}
      {taskUUID && images.length > 0 && (
        <div className="success-message">
          <h2>SUCCESS</h2>
          <div className="image-grid">
            {images.map((img, index) => (
              <img key={index} src={img.url} alt={`Result ${index}`} onClick={() => handleImageClick(img)} />
            ))}
          </div>
        </div>
      )}
      {selectedImage && (
        <div className="overlay" onClick={handleOverlayClick}>
          <img src={selectedImage.url} alt="Full Screen" className="full-screen-image" />
        </div>
      )}
    </div>
  );
}

export default AigcWorkflowCreatorForm;
