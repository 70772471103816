import React, { useState, useRef, useEffect } from 'react';
import { CanvasSketchCompleteEventEmitter } from '../../event/event';

function ImageSketchpad({ imageSrc, onImageSave }) {
  const imageCanvasRef = useRef(null);
  const maskCanvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [penSize, setPenSize] = useState(5);
  const [penColor, setPenColor] = useState('white');
  const [pastedImage, setPastedImage] = useState(null);
  const [pastedImageOffset, setPastedImageOffset] = useState({ x: 0, y: 0 });
  const [pastedImageScale, setPastedImageScale] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState(null);

  const handleMouseDown = (e) => {
    if (pastedImage && isWithinPastedImage(e.clientX, e.clientY)) {
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    } else {
      startDrawing(e.clientX, e.clientY);
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const dx = e.clientX - dragStart.x;
      const dy = e.clientY - dragStart.y;
      setPastedImageOffset((prev) => ({
        x: prev.x + dx,
        y: prev.y + dy,
      }));
      setDragStart({ x: e.clientX, y: e.clientY });
    } else if (isDrawing) {
      draw(e.clientX, e.clientY);
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
    } else if (isDrawing) {
      setIsDrawing(false);
    //   saveImage();
    }
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    if (pastedImage && isWithinPastedImage(touch.clientX, touch.clientY)) {
      setIsDragging(true);
      setDragStart({ x: touch.clientX, y: touch.clientY });
    } else {
      startDrawing(touch.clientX, touch.clientY);
    }
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    if (isDragging) {
      const dx = touch.clientX - dragStart.x;
      const dy = touch.clientY - dragStart.y;
      setPastedImageOffset((prev) => ({
        x: prev.x + dx,
        y: prev.y + dy,
      }));
      setDragStart({ x: touch.clientX, y: touch.clientY });
    } else if (isDrawing) {
      draw(touch.clientX, touch.clientY);
    }
  };

  const handleTouchEnd = () => {
    if (isDragging) {
      setIsDragging(false);
    } else if (isDrawing) {
      setIsDrawing(false);
    //   saveImage();
    }
  };

  const startDrawing = (x, y) => {
    const canvas = maskCanvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(x - rect.left, y - rect.top);
    setIsDrawing(true);
  };

  const draw = (x, y) => {
    const canvas = maskCanvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const ctx = canvas.getContext('2d');
    ctx.lineTo(x - rect.left, y - rect.top);
    ctx.strokeStyle = penColor;
    ctx.lineWidth = penSize;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.stroke();
  };

  const isWithinPastedImage = (x, y) => {
    const rect = maskCanvasRef.current.getBoundingClientRect();
    const imgX = pastedImageOffset.x + rect.left;
    const imgY = pastedImageOffset.y + rect.top;
    const imgWidth = pastedImage.img.width * pastedImageScale;
    const imgHeight = pastedImage.img.height * pastedImageScale;
    return x >= imgX && x <= imgX + imgWidth && y >= imgY && y <= imgY + imgHeight;
  };

  useEffect(() => {
    const imageCanvas = imageCanvasRef.current;
    const imageCtx = imageCanvas.getContext('2d');
    const maskCanvas = maskCanvasRef.current;

    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      imageCanvas.width = img.width;
      imageCanvas.height = img.height;
      maskCanvas.width = img.width;
      maskCanvas.height = img.height;

      imageCtx.drawImage(img, 0, 0);

      setWidth(img.width);
      setHeight(img.height);
    };

    const handleUpdatePensize = (newSize) => {
      setPenSize(newSize);
    };

    CanvasSketchCompleteEventEmitter.on('sketchComplete', saveImage);

    return () => {
      CanvasSketchCompleteEventEmitter.off('sketchComplete', saveImage);
    };
  }, [imageSrc]);

  const saveImage = () => {
    const maskCanvas = maskCanvasRef.current;
    const mergedCanvas = document.createElement('canvas');
    const mergedCanvasCtx = mergedCanvas.getContext('2d');

    mergedCanvas.width = maskCanvas.width;
    mergedCanvas.height = maskCanvas.height;

    mergedCanvasCtx.drawImage(imageCanvasRef.current, 0, 0);
    if (pastedImage) {
      mergedCanvasCtx.drawImage(
        pastedImage.img,
        pastedImageOffset.x,
        pastedImageOffset.y,
        pastedImage.img.width * pastedImageScale,
        pastedImage.img.height * pastedImageScale
      );
    }
    mergedCanvasCtx.drawImage(maskCanvasRef.current, 0, 0);

    const imgDataUrl = mergedCanvas.toDataURL('image/jpeg', 1.0);
    onImageSave(imgDataUrl);
  };

  const savePasteImage = () => {
    const imageCanvas = imageCanvasRef.current;
    const imageCtx = imageCanvas.getContext('2d');
    if (pastedImage) {
        imageCtx.drawImage(
        pastedImage.img,
        pastedImageOffset.x,
        pastedImageOffset.y,
        pastedImage.img.width * pastedImageScale,
        pastedImage.img.height * pastedImageScale
      );
    }
  };

  const handlePaste = (e) => {
    const clipboardItems = e.clipboardData.items;
    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];
      if (item.type.startsWith('image/')) {
        const blob = item.getAsFile();
        const img = new Image();
        img.onload = () => {
          setPastedImage({ img, x: 0, y: 0 });
        };
        img.src = URL.createObjectURL(blob);
      }
    }
  };

  const handleZoomIn = () => {
    if (pastedImage != null ) {
        setPastedImageScale(pastedImageScale + 0.05);
    } else {
        if (penSize < 100) {
            setPenSize(penSize+5)
        }
    }
  };

  const handleZoomOut = () => {
    if (pastedImage != null) {
        setPastedImageScale(pastedImageScale - 0.05);
    } else {
        if (penSize > 5) {
            setPenSize(penSize-5)
        }
    }
  };

  useEffect(() => {
    const maskCanvas = maskCanvasRef.current;
    const ctx = maskCanvas.getContext('2d');
    ctx.clearRect(0, 0, maskCanvas.width, maskCanvas.height);

    if (pastedImage) {
      ctx.drawImage(
        pastedImage.img,
        pastedImageOffset.x,
        pastedImageOffset.y,
        pastedImage.img.width * pastedImageScale,
        pastedImage.img.height * pastedImageScale
      );
    }
  }, [pastedImage, pastedImageOffset, pastedImageScale]);

  useEffect(() => {
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        width: `${width}px`,
        height: `${height}px`,
        overflow: 'hidden',
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <canvas
        ref={imageCanvasRef}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
      <canvas
        ref={maskCanvasRef}
        style={{
          border: '1px solid black',
          cursor: isDragging ? 'grabbing' : 'crosshair',
          position: 'absolute',
          background: 'transparent',
          top: 0,
          left: 0,
          touchAction: 'none',
        }}
      />
      <div style={{ position: 'absolute', top: 10, right: 10 }}>
        <button onClick={(e)=>setPenColor('white')}  style={{backgroundColor: 'white'}}></button>
        <button onClick={(e)=>setPenColor('black')} style={{backgroundColor: 'black'}}></button>
        <button onClick={(e)=>setPenColor('red')}  style={{backgroundColor: 'red'}}></button>
        <button onClick={(e)=>setPenColor('green')}  style={{backgroundColor: 'green'}}></button>
        <button onClick={(e)=>setPenColor('blue')}  style={{backgroundColor: 'blue'}}></button>
        <button onClick={(e)=>setPenColor('yellow')}  style={{backgroundColor: 'yellow'}}></button>

        <button onClick={handleZoomIn}>+</button>
        <button onClick={handleZoomOut}>-</button>
        <button onClick={savePasteImage}>save</button>
      </div>
    </div>
  );
}

export default ImageSketchpad;
