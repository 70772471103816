import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {Host} from '../../../config';
import { UpdateUserPoints } from '../../../user/User';
import '../../../css/SingleFaceWorkflow.css';
import T from '../../../language';
import { HttpPost, HttpPostForm } from '../../../http/HttpClient';

function SingleFaceWorkflow({ workflow, onCloseWorkflow, onComplete }) {
    const [srcImageFile, setSrcImageFile] = useState(null);
    const [targetImageFile, setTargetImageFile] = useState(null);
    const [srcImagePreview, setSrcImagePreview] = useState('');
    const [targetImagePreview, setTargetImagePreview] = useState('');
    const [message, setMessage] = useState('');
    const [taskUUID, setTaskUUID] = useState(null);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [faceRetouch, setFaceRetouch] = useState(false);

    const close = () => {
        onCloseWorkflow();
    };

    const handleSrcImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSrcImageFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setSrcImagePreview(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleTargetImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setTargetImageFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setTargetImagePreview(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('src_image', srcImageFile);
        formData.append('target_image', targetImageFile);
        formData.append('face_retouch', faceRetouch); 
        
        try {
            const token = Cookies.get("X-API-TOKEN");
            HttpPostForm('/faceswap/task/single_face', formData)
            .then((response) =>{
                const resp = response.data;
                console.log(resp.data);
                if (resp.error) {
                    setMessage(resp.error);
                } else {
                    setMessage('SUCCESS');
                    console.log(resp.data.task_uuid)
                    setTaskUUID(resp.data.task_uuid);
                    setLoading(true);
                    // Clear the state
                    setSrcImageFile(null);
                    setTargetImageFile(null);
                    setSrcImagePreview('');
                    setTargetImagePreview('');
                    UpdateUserPoints();
                }    
            })
            .catch ((error) => {
                console.error('Error uploading form data', error);
                setMessage('An error occurred while submitting the form.');
            })
        } catch (error) {
        }
    };

    const handleImageClick = (imgUrl) => {
        setSelectedImage(imgUrl);
    };

    const handleOverlayClick = () => {
        setSelectedImage(null);
    };

    useEffect(() => {
        if (taskUUID) {
            const interval = setInterval(async () => {
                try {
                    const token = Cookies.get("X-API-TOKEN");
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + token
                    };

                    const response = await axios.get(`${Host.Server}/faceswap/task/result/${taskUUID}`, { headers });
                    console.log(response)
                    const resultData = response.data.data;
                    if (resultData.result && resultData.result.length > 0) {
                        console.log(resultData.result)
                        setImages(resultData.result);
                        setLoading(false);
                        clearInterval(interval);
                    }
                } catch (error) {
                    console.error('Error fetching task result', error);
                }
            }, 4000);
            return () => clearInterval(interval);
        }
    }, [taskUUID]);

    return (
        <div className="workflow-container">
            {message === 'SUCCESS' && !loading ? (
                <div className="success-message">
                    <h2>SUCCESS</h2>
                    <div className="image-grid">
                        {images.map((imgUrl, index) => (
                            <img key={index} src={imgUrl} alt={`Result ${index}`} onClick={() => handleImageClick(imgUrl)} />
                        ))}
                    </div>
                </div>
            ) : (
                <div>
                    <h2>{T(workflow.name)}</h2>
                    <h2>{T("Cost")} {workflow.cost}</h2>
                    <div className="input-section">
                        <div className="image-upload">
                            <label htmlFor="srcImageInput">{T("Source Face Image")}</label>
                            <input id="srcImageInput" type="file" accept="image/*" onChange={handleSrcImageChange} />
                            {srcImagePreview && <img src={srcImagePreview} alt="Source Preview" />}
                        </div>
                        <div className="image-upload">
                            <label htmlFor="targetImageInput">{T("Target Image")}</label>
                            <input id="targetImageInput" type="file" accept="image/*" onChange={handleTargetImageChange} />
                            {targetImagePreview && <img src={targetImagePreview} alt="Target Preview" />}
                        </div>
                        <div className="checkbox-option">
                            <label htmlFor="faceRetouch">
                                <input
                                    type="checkbox"
                                    id="faceRetouch"
                                    checked={faceRetouch}
                                    onChange={() => setFaceRetouch(!faceRetouch)}
                                />
                                {T("Face Retouch")}
                            </label>
                        </div>
                    </div>
                    <div className="button-section">
                        <button className="submit-button" onClick={handleSubmit}>{T("Submit")}</button>
                        <button className="back-button" onClick={close}>{T("Back")}</button>
                    </div>
                    {message && <div className={`message ${message === 'SUCCESS' ? 'success' : 'error'}`}>{message}</div>}
                </div>
            )}
            {loading && (
                <div className="loading-spinner">
                    <p>Generating... Please wait.</p>
                    <div className="spinner"></div>
                    <p>{T('You can close and check the result in "Gallery"')}</p>
                    <button className="submit-button" onClick={onComplete}>Complete</button>
                </div>
            )}
            {selectedImage && (
                <div className="overlay" onClick={handleOverlayClick}>
                    <img src={selectedImage} alt="Full Screen" className="full-screen-image" />
                </div>
            )}
        </div>
    );
}

export default SingleFaceWorkflow;
