import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import ReactPlayer from 'react-player';
import {Host} from '../../../config';
import { UpdateUserPoints } from '../../../user/User';
import '../../../css/SingleFaceWorkflow.css';
import T from '../../../language';
import { HttpPostForm } from '../../../http/HttpClient';

function SingleFaceVideoWorkflow({ workflow, onCloseWorkflow, onComplete }) {
    const [srcImageFile, setSrcImageFile] = useState(null);
    const [targetImageFile, setTargetImageFile] = useState(null);
    const [targetVideoFile, setTargetVideoFile] = useState(null);
    const [srcImagePreview, setSrcImagePreview] = useState('');
    const [targetImagePreview, setTargetImagePreview] = useState('');
    const [targetVideoPreview, setTargetVideoPreview] = useState('');
    const [message, setMessage] = useState('');
    const [taskUUID, setTaskUUID] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const close = () => {
        onCloseWorkflow();
    };

    const handleSrcImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check file size (limit to 5MB)
            if (file.size > 5 * 1024 * 1024) { // 5MB in bytes
                setMessage('Source image file size must be less than 5MB.');
                return;
            }
            setSrcImageFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setSrcImagePreview(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleTargetImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check file size (limit to 5MB)
            if (file.size > 5 * 1024 * 1024) { // 5MB in bytes
                setMessage('Target image file size must be less than 5MB.');
                return;
            }
            setTargetImageFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setTargetImagePreview(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleTargetVideoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check file size (limit to 50MB)
            if (file.size > 50 * 1024 * 1024) { // 50MB in bytes
                setMessage('Video file size must be less than 50MB.');
                return;
            }
            setTargetVideoFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setTargetVideoPreview(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('src_image', srcImageFile);
        formData.append('target_image', targetImageFile);
        formData.append('target_video', targetVideoFile);

        HttpPostForm('/faceswap/task/single_face_video', formData)
        .then((response) => {
            const resp = response.data;
            console.log(resp.data);
            if (resp.error) {
                setMessage(resp.error);
            } else {
                setMessage('SUBMITTED');
                console.log(resp.data.task_uuid);
                setTaskUUID(resp.data.task_uuid);
                setLoading(true);
                // Clear the state
                setSrcImageFile(null);
                setTargetImageFile(null);
                setTargetVideoFile(null);
                setSrcImagePreview('');
                setTargetImagePreview('');
                setTargetVideoPreview('');
                UpdateUserPoints();
            }
        })
        .catch((error)=>{
            console.error('Error uploading form data', error);
            setMessage('An error occurred while submitting the form.');            
        })
    };

    const handleVideoClick = (videoUrl) => {
        setSelectedVideo(videoUrl);
    };

    const handleOverlayClick = () => {
        setSelectedVideo(null);
    };

    useEffect(() => {
        if (taskUUID) {
            const interval = setInterval(async () => {
                try {
                    const token = Cookies.get("X-API-TOKEN");
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + token
                    };

                    const response = await axios.get(`${Host.Server}/faceswap/task/result/${taskUUID}`, { headers });
                    console.log(response)
                    const resultData = response.data.data;
                    if (resultData.result && resultData.result.length > 0) {
                        console.log(resultData.result)
                        setVideoUrl(resultData.result[0]);
                        setLoading(false);
                        clearInterval(interval);
                        setMessage("SUCCESS");
                    }
                } catch (error) {
                    console.error('Error fetching task result', error);
                }
            }, 4000);
            return () => clearInterval(interval);
        }
    }, [taskUUID]);

    return (
        <div className="workflow-container">
            {message === 'SUCCESS' && !loading && (
                <div className="success-message">
                    <h2>SUCCESS</h2>
                    <div className="video-grid">
                        <ReactPlayer
                            key={taskUUID}
                            url={videoUrl}
                            controls
                            width="100%"
                            onClick={() => handleVideoClick(videoUrl)}
                        />
                    </div>
                </div>
            )} 
            
            {message === '' && !loading && (
                <div>
                    <h2>{T(workflow.name)}</h2>
                    <h2>{T("Cost")} {workflow.cost}</h2>
                    <div className="input-section">
                        <div className="image-upload">
                            <label htmlFor="srcImageInput">{T("Source Face Image")}</label>
                            <input id="srcImageInput" type="file" accept=".jpg,.jpeg,.png" onChange={handleSrcImageChange} />
                            {srcImagePreview && <img src={srcImagePreview} alt="Source Preview" />}
                        </div>
                        <div className="image-upload">
                            <label htmlFor="targetImageInput">{T("Target Image(optional)")}</label>
                            <input id="targetImageInput" type="file" accept=".jpg,.jpeg,.png" onChange={handleTargetImageChange} />
                            {targetImagePreview && <img src={targetImagePreview} alt="Target Preview" />}
                        </div>
                        <div className="video-upload">
                            <label htmlFor="targetVideoInput">{T("Target Video")}</label>
                            <input id="targetVideoInput" type="file" accept=".mp4,.avi,.mov" onChange={handleTargetVideoChange} />
                            {targetVideoPreview && (
                                <ReactPlayer
                                    url={targetVideoPreview}
                                    controls
                                    width="100%"
                                    height="300px"
                                />
                            )}
                        </div>
                    </div>
                    <label>{T("generated video is limitd to 15 seconds")}</label>
                    <div className="button-section">
                        <button className="submit-button" onClick={handleSubmit}>{T("Submit")}</button>
                        <button className="back-button" onClick={onclose}>{T("Close")}</button>
                    </div>
                    {message && <div className={`message ${message === 'SUCCESS' ? 'success' : 'error'}`}>{message}</div>}
                </div>
            )}
            {loading && (
                <div className="loading-spinner">
                    <p>Generating... Please wait.</p>
                    <div className="spinner"></div>
                    <p>{T('You can close and check the result in "Gallery"')}</p>
                    <button className="submit-button" onClick={onComplete}>Close</button>
                </div>
            )}
            {selectedVideo && (
                <div className="overlay" onClick={handleOverlayClick}>
                    <ReactPlayer url={selectedVideo} controls playing width="100%" height="100%" />
                </div>
            )}
        </div>
    );
}

export default SingleFaceVideoWorkflow;
