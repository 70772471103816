import React, { useState, useEffect } from 'react';
import { FaceswapWorkflows } from '../../../config';
import MultipleFaceWorkflow from './MultipleFaceWorkflow';
import SingleFaceWorkflow from './SingleFaceWorkflow';
import { HttpGet } from '../../../http/HttpClient';
import '../../../css/CreatorServiceMenu.css';
import T from '../../../language';
import { getCachedMembership } from '../../common/util';

function CreatorMenu({ onCreatorClose }) {
    const [selectedWorkflow, setSelectedWorkflow] = useState(null);
    const [membership, setMembership] = useState(0);
    const [key, setKey] = useState(0);

    function handleClick(wf) {
        setSelectedWorkflow(wf);
    }

    function close() {
        setSelectedWorkflow(null);
    }

    useEffect(() => {
        const updateMembership = async () => {
            var v = await getCachedMembership();
            setMembership(v);
        };
        updateMembership(); // Directly call the function
    }, []); // Empty dependency array to call only once

    const getMembershipRequirement = (workflowMembership) => {
        if (workflowMembership === 1) {
            return (
                <div>
                    <div>{T("Require BASIC")}</div>
                    <img src="./image/membership_basic.jpg" />
                </div>
            );
        }
        if (workflowMembership === 2) {
            return (
                <div>
                    <div>{T("Require PREMIUM")}</div>
                    <img src="./image/membership_premium.jpg" />
                </div>
            );
        }
        if (workflowMembership === 3) {
            return (
                <div>
                    <div>{T("Require VIP")}</div>
                    <img src="./image/membership_vip.jpg" />
                </div>
            );
        }
    };

    return (
        <div key={key} className="service-menu-container">
            {selectedWorkflow == null && (
                <div className="service-grid">
                    {FaceswapWorkflows.map((workflow, index) => {
                        console.log("membership: " + membership + "   workflow membership: " + workflow.membership);
                        if (membership >= workflow.membership) {
                            return (
                                <div className="service-card" key={index} onClick={() => handleClick(workflow)}>
                                    <img src={workflow.imageUrl} className="service-image" />
                                    <div className="service-info">
                                        <h3>{T(workflow.name)}</h3>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div className="service-card-grayed-out" key={index}>
                                    <img src={workflow.imageUrl} className="service-image-blurred" />
                                    <div className="service-info">
                                        <h3>{T(workflow.name)}</h3>
                                        {getMembershipRequirement(workflow.membership)}
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            )}
            {selectedWorkflow && selectedWorkflow.workflow_name === 'MultipleFaceWorkflow' && (
                <MultipleFaceWorkflow workflow={selectedWorkflow} onCloseWorkflow={close} onComplete={onCreatorClose} />
            )}
            {selectedWorkflow && selectedWorkflow.workflow_name === 'SingleFaceWorkflow' && (
                <SingleFaceWorkflow workflow={selectedWorkflow} onCloseWorkflow={close} onComplete={onCreatorClose} />
            )}
        </div>
    );
}

export default CreatorMenu;
