import React, { useState } from 'react';
import DigitalModelEditor from './gallery/DigitalModelEditor.js';
import T from "../../language.js"
import DigitalModelCreatorMenu from './creator/DigitalModelCreatorMenu.js';

function DigitalModelMenu() {
    const [selectedService, setSelectedService] = useState("creator");

    function closeCreator() {
        setSelectedService("gallery")
    }

    return (
        <div>
            <h2>{T("Digital Model")}</h2>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                <div style={{ margin: '0 10px', padding: '10px', cursor: 'pointer', borderBottom: selectedService === 'creator' ? '3px solid blue' : '' }} 
                     onClick={() => { setSelectedService("creator"); } }>
                    {T("Creator")}
                </div>
                <div style={{ margin: '0 10px', padding: '10px', cursor: 'pointer', borderBottom: selectedService === 'gallery' ? '3px solid blue' : '' }} 
                     onClick={() => setSelectedService('editor')}>
                    {T("Editor")}
                </div>
            </div>

            {selectedService === "creator" && <DigitalModelCreatorMenu key={new Date().getTime()} onCreatorClose={closeCreator} />}
            {selectedService === "editor" && <DigitalModelEditor />}
        </div>
    )
}

export default DigitalModelMenu;