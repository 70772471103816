import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import T from "../../../language"
import '../../../css/Gallery.css';
import { HttpGet } from '../../../http/HttpClient';
import { encodeJson } from '../../common/util';

function DigitalModelCreatorMenu({ onCreatorModeClose }) {
  const [selecteDigitalModel, setSelecteDigitalModel] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [digitalModels, setDigitalModels] = useState([])


  const fetchDigitalModels = async (page) => {
    HttpGet(`/digitalmodel/model/list?start_page=${currentPage}&page_size=${pageSize}`)
    .then((response)=>{
      console.log(response.data)
      setDigitalModels(response.data.data.result)
    })
    .catch((error)=>{
      console.log(error)
    })
  };

  useEffect(() => {
    fetchDigitalModels(currentPage);
  }, [currentPage]);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
        setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
      setCurrentPage(currentPage + 1);
  };

  const pickDigitalModel = (dm) => {
    console.log("pick " + dm.uuid)
    setSelecteDigitalModel(dm)
  }


  return (
    <div className="gallery-container">
      <h2>{T("Pick a Model")}</h2>
            <div className="digital-model-grid">
                {digitalModels.map((dm, index) => (
                  <Link to={"/digitalmodel/editor/"+encodeJson(dm)}>
                    <div className="digital-model-card" key={index} onClick={() => pickDigitalModel(dm)}>
                        {dm.name}
                        {dm.image_url !== "" && dm.image_url !== undefined && <img src={dm.image_url} alt="" />}
                    </div>
                  </Link>
                ))}
            </div>

            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 0}>Previous</button>
                <button onClick={handleNextPage} disabled={digitalModels.length == 0}>Next</button>
            </div>
      </div>
  );
}

export default DigitalModelCreatorMenu;
