import axios from "axios";
import Cookies from 'js-cookie';
import { Host } from "../config";

export const HttpGet = async (url) => {
    const serverUrl = Host.Server + url;
    const token = Cookies.get("X-API-TOKEN");
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + token
    };
    return axios.get(serverUrl, { headers });
}

export const HttpGetWithoutToken = async (url, payload) => {
  const serverUrl = Host.Server + url;
  return axios.get(serverUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
}


export const HttpPost = async (url, payload) => {
    const serverUrl = Host.Server + url;
    const token = Cookies.get("X-API-TOKEN");
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + token
    };
    return axios.post(serverUrl, payload, { headers });
}

export const HttpPostWithoutToken = async (url, payload) => {
    const serverUrl = Host.Server + url;
    return axios.post(serverUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
}


export const HttpPostForm = async (url, payload) => {
    const token = Cookies.get("X-API-TOKEN");
    const serverUrl = Host.Server + url;
    return axios.post(serverUrl, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Token ' + token
        },
      });
}

export const HttpPostFormWithoutAuth = async (url, payload) => {
  const serverUrl = Host.Server + url;
  return axios.post(serverUrl, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
}