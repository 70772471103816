import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './user/Login.js';
import './user/Register.js';
import Service from './service/service.js';
import UserPopup from './user/UserPopup.js'
import './css/App.css';
import { UpdateUserPoints } from './user/User.js';
import { HttpPostWithoutToken } from './http/HttpClient.js';
import PaypalPayment from './service/payment/PaypalPayment.js';
import HomeSection from './HomeSection.js';
import { isLoggedIn } from './service/common/util.js';

function Home({showLoginSection, showRegisterSection, inviteCode, onLogin}) {
  const [key, setKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  // const [showUserPopup, setShowUserPopup] = useState(false);
  // const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  // const [showLoginSection, setShowLoginSection] = useState(false);
  // const [showRegisterSection, setShowRegisterSection] = useState(false);
  // const [inviteCode, setInviteCode] = useState('');

  const handleLogin = async (username, password) => {
    const data = {
      username: username,
      password: password
    };
    HttpPostWithoutToken('/user/login', data)
    .then((response) => {
      var respData = response.data.data;
      if (respData != null) {
        console.log(respData.jwt_token);
        Cookies.set("X-API-TOKEN", respData.jwt_token, { expires: 7 });
        Cookies.set("X-USERNAME", respData.username, { expires: 7 });
        onLogin();
        reload();
      }
    })
    .catch((error) => {
      console.log('There was a problem with the axios operation:', error);
      setErrorMessage("incorrect login");
    });
  };

  const reload = () => {
    setKey(prevKey => prevKey + 1);
    UpdateUserPoints();
  };


  // useEffect(() => {
  //   if (inviteCode === "") {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const code = urlParams.get('invite_code');
  //     if (code) {
  //       setInviteCode(code);
  //       setShowRegisterSection(true);
  //       setShowLoginSection(false);
  //     }
  //   }

  //   reload();
  // }, [showLoginSection, showRegisterSection]);


  // const closeUserPopup = () => {
  //   setShowUserPopup(false);
  // }

  // const closePaymentPopup = () => {
  //   setShowPaymentPopup(false);
  // }

  return (
    <div key={key}>
      {isLoggedIn() ? <Service /> : (
        <div>
          <HomeSection onLogin={handleLogin}  
          showLoginSection={showLoginSection} 
          showRegisterSection={showRegisterSection}
          inviteCode={inviteCode}
          />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      )}
      {/* {showUserPopup && <UserPopup onClose={closeUserPopup} />}
      {showPaymentPopup && (
        <div className="user-popup">
          <PaypalPayment onClose={closePaymentPopup}/>
        </div>
      )} */}
    </div>
  );
}

export default Home;
