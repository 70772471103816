import React, { useState, useEffect } from 'react';
import MultipleFaceWorkflowStep2 from '../creator/MultipleFaceWorkflowStep2';
import '../../../css/FaceswapGallery.css';
import { HttpGet, HttpPost } from '../../../http/HttpClient';
import T from '../../../language';

function FaceswapGallery() {
    const [activeImage, setActiveImage] = useState(null);
    const [userImages, setUserImages] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(20); // Define page size here

    const openImage = (img) => {
        setActiveImage(img);
        document.body.style.overflow = 'hidden';
    };

    const closeImage = () => {
        setActiveImage(null);
        document.body.style.overflow = 'auto';
    };

    const closePending = () => {
        setActiveImage(null);
        document.body.style.overflow = 'auto';
        fetchUserImages();
    }

    const fetchUserImages = () => {
        HttpGet(`/faceswap/image/browse?page=${currentPage}&size=${pageSize}` )
        .then((response)=>{
            const result = response.data.data.result;
            console.log(result)
            setUserImages(result);
        })
        .catch((error)=>{
            console.error('Error fetching user images:', error);
        })
    };

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    useEffect(() => {
        fetchUserImages();
    }, [currentPage]);

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const saveAsPhoto = () => {
        // setIsEditMode(true)
        console.log(activeImage)

        HttpPost('/digitalmodel/photo/add', {
            'task_uuid': activeImage.dm_task_uuid,
            'bucket': activeImage.bucket,
            'object': activeImage.object
        })
        .then((response)=>{
            console.log(response.data)
            fetchUserImages(currentPage)
        })
        .catch((error)=>{
            console.log(error)
        })
    };


    return (
        <div className="faceswap-gallery-container">
            <div className="image-grid">
                {userImages.map((img, index) => (
                    <div className="image-card" key={index} onClick={() => openImage(img)}>
                        {img.img_url != "" && <img src={img.img_url} />}
                        {(img.img_url === "" || img.img_url === undefined ) && img.status==="0" && <img src="./image/pending.jpg" />}                        
                        {(img.img_url === "" || img.img_url === undefined ) && img.status==="1" && <img src="./image/loading-green.gif" />}                        
                        {(img.img_url === "" || img.img_url === undefined ) && img.status==="4" && <img src="./image/task_needs_user_input.png" />}                        
                    </div>
                ))}
            </div>

            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 0}>Previous</button>
                <button onClick={handleNextPage} disabled={userImages.length < pageSize}>Next</button>
            </div>

            {activeImage && (activeImage.faces==undefined || activeImage.faces.length==0) && (
                <div className="image-overlay" onClick={closeImage}>
                    <div className="overlay-content">
                        <span>{activeImage.task_uuid}</span>
                        <img src={activeImage.img_url} alt="Zoomed"/>
                        <p>{activeImage.prompt}</p>
                        <span>Model: {activeImage.modelName}</span>
                        {
                            activeImage.dm_uuid !== undefined && activeImage.dm_uuid !== "" && activeImage.is_photo !== "true" && (
                                <button onClick={(e)=>{
                                    // e.preventDefault();
                                    // e.stopPropagation();
                                    saveAsPhoto();
                                    }} className="delete-button">{T("Use For Photo")}
                                </button>
                            )
                        }
                    </div>
                </div>
            )}

            {activeImage && activeImage.faces!=undefined && activeImage.faces.length>0 && (
                <div className="image-overlay">
                    <MultipleFaceWorkflowStep2 taskUuid={activeImage.task_uuid} faces={JSON.parse(activeImage.faces)} onClose={closePending} />
                </div>
            )}

        </div>
    );
}

export default FaceswapGallery;
