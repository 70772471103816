import React, { useState, useEffect } from 'react';
import '../css/RegisterSection.css'
import '../css/App.css';

import { HttpPostWithoutToken } from '../http/HttpClient';
import T from "../language"

function RegisterSection({ setIsLogin, inviteCode }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [message, setMessage] = useState('');
  const [isUserCreated, setIsUserCreated] = useState(false);
  const [invite, setInvite] = useState(inviteCode || '');

  const validateUsername = (username) => {
    const usernameRegex = /^[a-zA-Z0-9]{5,15}$/;
    return usernameRegex.test(username);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^[a-zA-Z0-9.!@#$]{5,15}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateUsername(username)) {
      setMessage('Username must be only letters and numbers, at least 5 and less than 16 characters.');
      return;
    }

    if (!validatePassword(password)) {
      setMessage('Password must be letters, numbers, and special symbols from .!@#$, at least 5 and less than 16 characters.');
      return;
    }

    if (password !== password2) {
      setMessage('Passwords do not match.');
      return;
    }

    HttpPostWithoutToken('/user/register', { 
      "username": username, 
      "password": password,
      "invite_code": invite
    })
    .then((response) => {
      if (response.data.error) {
        setMessage(response.data.error);
      } else {
        setMessage('');
        setIsUserCreated(true);
      }
    })
    .catch((error) => {
      setMessage('Error creating user:' + error.response.data.error);
    })
  };

  useEffect(() => {
    if (inviteCode) {
      setInvite(inviteCode);
    }
  }, [inviteCode]);

  return (
    <div className="auth-form">
      <h2>{T("Create New Account")}</h2>
      {
        !isUserCreated && (
          <div>
          <form onSubmit={handleSubmit}>
            <input
              name="username"
              type="text"
              placeholder={T("Username")}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              name="password"
              type="password"
              placeholder={T("Password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              name="password2"
              type="password"
              placeholder={T("Password(type again)")}
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
            <input
              name="invite"
              type="text"
              placeholder={T("Invite Code(Optional)")}
              value={invite}
              onChange={(e) => setInvite(e.target.value)}
            />
            <button type="submit">{T("Create")}</button>
          </form>
          {message && <p className="error-message">{message}</p>}
        </div>
        )
      }
      
      <p onClick={() => setIsLogin(true)}>
        {isUserCreated ? T("User Created. Continue to login") : T("Back to Login")}
      </p>
    </div>
  );
}

export default RegisterSection;
