import React, { useState } from 'react';
import FaceswapGallery from './gallery/FaceswapGallery';
import CreatorMenu from './creator/CreatorMenu';
import T from "../../language.js"

function FaceswapMenu() {
    const [selectedService, setSelectedService] = useState("creator");

    function closeCreator() {
        setSelectedService("gallery")
    }

    return (
        <div>
            <h2>{T("Image Face Swap")}</h2>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                <div style={{ margin: '0 10px', padding: '10px', cursor: 'pointer', borderBottom: selectedService === 'creator' ? '3px solid blue' : '' }} 
                     onClick={() => { setSelectedService("creator"); } }>
                    {T("Creator")}
                </div>
                <div style={{ margin: '0 10px', padding: '10px', cursor: 'pointer', borderBottom: selectedService === 'gallery' ? '3px solid blue' : '' }} 
                     onClick={() => setSelectedService('gallery')}>
                    {T("Gallery")}
                </div>
            </div>

            {selectedService === "creator" && <CreatorMenu key={new Date().getTime()} onCreatorClose={closeCreator} />}
            {selectedService === "gallery" && <FaceswapGallery />}
        </div>
    )
}

export default FaceswapMenu;