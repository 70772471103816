import React, { useState, useEffect } from 'react';
import { UpdateUserPoints } from '../../../user/User';
import { InpaintingModels } from '../../../config';
import '../../../css/ImageGeneration.css';
import T from '../../../language'
import { HttpPostForm, HttpPost, HttpGet } from '../../../http/HttpClient';
import ImageMasker from '../../common/ImageMasker';
import Resizer from 'react-image-file-resizer';
import { imageDataURLtoFile } from '../../common/util';
import { CanvasSketchCompleteEventEmitter } from '../../../event/event';
import ImageSketchpad from '../../common/ImageSketchpad';

function InpaintingCreatorForm({ service, onCloseCreatorForm }) {
  const [textAreaValue, setTextAreaValue] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [seedValue, setSeedValue] = useState(0);
  const [selectedModel, setSelectedModel] = useState(InpaintingModels[0].id); // Default to the first model
  const [message, setMessage] = useState(null);
  const [taskUUID, setTaskUUID] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [mask, setMask] = useState('');
  const [step, setStep] = useState(0);
  const [strength, setStrength] = useState('1.0');
  const [imageWidth, setImageWidth] = useState(0);

  const handleSubmit = async (e) => {
    // e.preventDefault();
    // if (!ableToSubmit()) {
    //   return;
    // }
    if (imagePreview === null || imagePreview === '') {
      setMessage("Image file required");
      return;
    } 

    if (mask === '') {
      setMessage('no mask found');
      return;
    }

    const resizedImageFile = imageDataURLtoFile(imagePreview)
    if (resizedImageFile === null) {
      setMessage("failed to convert resized image file");
      return;
    } 

    const formData = new FormData();
    formData.append('prompt', textAreaValue);
    formData.append('seed', seedValue);
    formData.append('strength', strength);
    formData.append('model_id', selectedModel); // Use selected model_id
    if (resizedImageFile) {
      formData.append('file', resizedImageFile);
    }
    formData.append('mask_data', mask);

    try {
      HttpPostForm('/gallery/task/inpainting/add_user_image', formData)
        .then((response) => {
          const resp = response.data;
          console.log(resp.data);
          if (resp.error) {
              setMessage(resp.error);
          } else if (resp.data) {
              setMessage('Pending processing...');
              console.log(resp.data.task_uuid)
              setTaskUUID(resp.data.task_uuid);
              // Clear the state
              setTextAreaValue('');
              // setImageFile(null);
              // setImagePreview('');
              UpdateUserPoints();
          }
        })
        .catch((err) => {
          setMessage(err.response.data.error);
          console.log(err.response);
        });
      setMessage('Submitting...');
    } catch (error) {
      console.error('Error uploading form data', error);
      setMessage('Error submitting the form');
    }
  };

  const closeForm = () => {
    onCloseCreatorForm();
  };

  const ableToSubmit = () => {
    return message == null;
  };


  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024, // max width
        1024, // max height
        'JPEG', // output format
        100, // quality
        0, // rotation
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMessage(null);
      // setImageFile(file);

      resizeFile(file).then((resized) => {
        // setResizedImage(resized);
        const img = new Image();
        img.onload = () => {
          setImageWidth(img.width);
          setImagePreview(resized);
        }
        img.src = resized;
      });
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   setImagePreview(e.target.result);
      // };
      // reader.readAsDataURL(file);
    }
  };

  const handleImageClick = (img) => {
    setSelectedImage(img);
  };

  const handleOverlayClick = () => {
    setSelectedImage(null);
  };

  const handleMaskSave = (maskData) => {
    setMask(maskData);
  };

  const handleImagePreviewSave = (imgData) => {
    setImagePreview(imgData);
    setStep(1)

  };

  useEffect(() => {
    if (taskUUID) {
        const interval = setInterval(async () => {
          HttpGet(`/gallery/task/result/${taskUUID}`)
          .then((response)=>{
            console.log(response);
            const resultData = response.data.data;
            if (resultData.result && resultData.result.length > 0) {
                console.log(resultData.result);
                setImages(resultData.result);
                clearInterval(interval);
            }
          })
          .catch((error)=>{
            console.error('Error fetching task result', error);
          })

            // try {
            //     const token = Cookies.get("X-API-TOKEN");
            //     const headers = {
            //         'Content-Type': 'application/json',
            //         'Authorization': 'Token ' + token
            //     };

            //     const response = await axios.get(`${Host.Server}/gallery/task/result/${taskUUID}`, { headers });
            //     console.log(response);
            //     const resultData = response.data.data;
            //     if (resultData.result && resultData.result.length > 0) {
            //         console.log(resultData.result);
            //         setImages(resultData.result);
            //         clearInterval(interval);
            //     }
            // } catch (error) {
            //     console.error('Error fetching task result', error);
            // }
        }, 4000);
        return () => clearInterval(interval);
    }
  }, [taskUUID,imageWidth]);

  return (
    <div>
      {step == 0 && (
          <div className="preview-container">
          <label htmlFor="imageInput">Upload Image:</label>
          <input id="imageInput" type="file" accept="image/*" onChange={handleFileChange} />
          {/* {imagePreview && <img src={imagePreview} alt="Preview" />} */}
          {imagePreview && <ImageSketchpad imageSrc={imagePreview} onImageSave={handleImagePreviewSave} />}
          {imagePreview != '' && (
           <button onClick={(e)=>{
            e.preventDefault();
            CanvasSketchCompleteEventEmitter.emit('sketchComplete', null);
           }}>Next</button>
          )}
        </div>
      )}

      {step == 1 && (
        
        <div className="generation-container" style={{width: `${imageWidth}px`}}>
          <label>{service.name}</label>

          <ImageMasker
              imageSrc={imagePreview}
              onMaskSave={handleMaskSave}
          />

          <div className="input-container">
            <label htmlFor="modelSelect">Select Model:</label>
            <select
              id="modelSelect"
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
            >
              {InpaintingModels.map((model) => (
                <option key={model.id} value={model.id}>
                  {model.name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-container">
            <label htmlFor="textarea">Replace With</label>
            <textarea id="textarea" value={textAreaValue} onChange={(e) => setTextAreaValue(e.target.value)} />
          </div>
              
          <div className="input-container">
            <label htmlFor="seedValue">Seed:</label>
            <input type="text" id="seedValue" value={seedValue} onChange={(e) => setSeedValue(e.target.value)} />
          </div>
          <div className="input-container">
            <label htmlFor="strength">Strength:</label>
            <input type="text" id="strength" value={strength} onChange={(e) => setStrength(e.target.value)} />
          </div>

          <div className="button-container">
            <button onClick={(e) => handleSubmit(e)}>Submit</button>
            <button onClick={(e) => closeForm()}>Back</button>
          </div>
          {message && <div className={`message ${message === 'SUCCESS' ? 'success' : 'error'}`}>{message}</div>}
        </div>
      )}

      {taskUUID && images.length === 0 && (
        <div className="loading-spinner" onClick={closeForm}>
          <p>Generating... Please wait.</p>
          <div className="spinner"></div>
          <p>{T('You can close and check the result in "Gallery"')}</p>
          <button onClick={(e) => closeForm()}>Close</button>
        </div>
      )}
      {taskUUID && images.length > 0 && (
        <div className="success-message">
          <h2>SUCCESS</h2>
          <div className="image-grid">
            {images.map((img, index) => (
              <img key={index} src={img.url} alt={`Result ${index}`} onClick={() => handleImageClick(img)} />
            ))}
          </div>
        </div>
      )}
      {selectedImage && (
        <div className="overlay" onClick={handleOverlayClick}>
          <img src={selectedImage.url} alt="Full Screen" className="full-screen-image" />
        </div>
      )}
    </div>
  );
}

export default InpaintingCreatorForm;
