import React, { useState } from 'react';
import T from '../language';
import '../css/App.css';

function LoginSection({ onLogin, setIsLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission
        onLogin(username, password);
    };

    return (
        <div className="auth-form">
            <h2>{T("Login")}</h2>
            <form onSubmit={handleSubmit}>
              <input type="text" placeholder={T("Username")} value={username} onChange={handleUsernameChange}/>
              <input type="password" placeholder={T("Password")} value={password} onChange={handlePasswordChange}/>
              <button type="submit">{T("Login")}</button>
            </form>
            <p onClick={() => setIsLogin(false)}>{T("Create New Account")}</p>
        </div>
    );
}

export default LoginSection;
