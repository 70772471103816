import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {Host} from '../../../config';
import '../../../css/MultipleFaceWorkflowStep2.css';
import '../../../css/Spinner.css';
import T from '../../../language'
import { HttpPost } from '../../../http/HttpClient';

function MultipleFaceWorkflowStep2({ taskUuid, faces, onClose, onComplete }) {
    const [sourceFaces, setSourceFaces] = useState([]);
    const [targetFaces, setTargetFaces] = useState([]);
    const [mapping, setMapping] = useState({});
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [step, setStep] = useState(2)

    useEffect(() => {
        setSourceFaces(faces.src_faces);
        setTargetFaces(faces.target_faces);
        console.log(faces);
    }, [faces]);

    const handleSelect = (targetIndex, sourceIndex) => {
        setMapping(prevMapping => {
            const newMapping = { ...prevMapping };
            if (newMapping[targetIndex] === sourceIndex) {
                delete newMapping[targetIndex]; // Unselect if the same source face is clicked again
            } else {
                newMapping[targetIndex] = sourceIndex;
            }
            return newMapping;
        });
    };

    const handleSubmit = async () => {
        const payload = {
            mapping: mapping,
            task_uuid: taskUuid
            // Add any other necessary data here
        };
        HttpPost('/faceswap/task/multi_faces/mapping', payload)
        .then((response) => {
            console.log(response.data);
            setLoading(true)
            setStep(3)

        })
        .catch((error)=>{
            console.error('Error submitting mapping data', error);

        })
    };

    useEffect(() => {
        if (loading) {
            const interval = setInterval(async () => {
                try {
                    const token = Cookies.get("X-API-TOKEN");
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + token
                    };

                    const response = await axios.get(`${Host.Server}/faceswap/task/result/${taskUuid}`, { headers });
                    console.log(response);
                    const resultData = response.data.data;
                    if (resultData.result && resultData.result.length > 0) {
                        console.log(resultData.result);
                        setImages(resultData.result);
                        setLoading(false);
                        clearInterval(interval);
                        setLoading(false);
                    }
                } catch (error) {
                    console.error('Error fetching task result', error);
                }
            }, 4000);
            return () => clearInterval(interval);
        }
    }, [loading]);

    const handleImageClick = (imgUrl) => {
        setSelectedImage(imgUrl);
    };

    const handleOverlayClick = () => {
        setSelectedImage(null);
    };

    return (
        <div>
            {step===2 && 
                <div className="workflow-container">
                    <h2>Select Faces to Swap</h2>
                    {targetFaces.map((targetUrl, targetIndex) => (
                        <div key={targetIndex} className="row-container">
                            <div className="target-card">
                                <label>Target Face</label>
                                <img src={targetUrl} alt={`Target ${targetIndex}`} />
                            </div>
                            <div className="source-grid">
                                <label>New Faces</label>
                                <div className="source-faces">
                                    {sourceFaces.map((sourceUrl, sourceIndex) => (
                                        <div
                                            key={sourceIndex}
                                            className={`source-item ${mapping[targetIndex] === sourceIndex ? 'selected' : ''}`}
                                            onClick={() => handleSelect(targetIndex, sourceIndex)}
                                        >
                                            <img src={sourceUrl} alt={`Source ${sourceIndex}`} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                    <button className="submit-button" onClick={handleSubmit}>Submit Mapping</button>
                </div>
            }
            {step===3 && loading && (
                <div className="loading-spinner">
                    <p>Generating... Please wait.</p>
                    <div className="spinner"></div>
                    <p>{T('You can close and check the result in "Gallery"')}</p>
                    <button className="submit-button" onClick={onComplete}>Close</button>
                </div>
            )}

            {step===3 && !loading && (
                <div className="success-message">
                <h2>SUCCESS</h2>
                <div className="image-grid">
                    {images.map((imgUrl, index) => (
                        <img key={index} src={imgUrl} alt={`Result ${index}`} onClick={() => handleImageClick(imgUrl)} />
                    ))}
                </div>
                <p>{T('You can close and check the result in "Gallery"')}</p>
                <button className="submit-button" onClick={onClose}>Close</button>
                </div>
            )}

            {selectedImage && (
                <div className="overlay" onClick={handleOverlayClick}>
                    <img src={selectedImage} alt="Full Screen" className="full-screen-image" />
                </div>
            )}

        </div>
    );
}

export default MultipleFaceWorkflowStep2;
