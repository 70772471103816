export const Items = {
	1: {
		"description": "Basic membership + 99 points",
		"cost":        1.9,
	},

	2: {
		"description": "Premium membership + 500 points",
		"cost":        9.9,
	},

	3: {
		"description": "vip membership + 1000 points",
		"cost":        19.9,
	},

	4: {
		"description": "500 points",
		"cost":        5.0,
	},

	5: {
		"description": "1000 points",
		"cost":        10.0,
	},
}
