import React, { useState } from 'react';
import FaceswapVideoGallery from './gallery/FaceswapVideoGallery';
import CreatorVideoMenu from './creator/CreatorVideoMenu';
import T from "../../language.js"

function FaceswapVideoMenu() {
    const [selectedService, setSelectedService] = useState("creator");

    function closeCreator() {
        setSelectedService("gallery")
    }

    return (
        <div>
            <h2>{T("Video Face Swap")}</h2>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                <div style={{ margin: '0 10px', padding: '10px', cursor: 'pointer', borderBottom: selectedService === 'creator' ? '3px solid blue' : '' }} 
                     onClick={() => { setSelectedService("creator"); } }>
                    Creator
                </div>
                <div style={{ margin: '0 10px', padding: '10px', cursor: 'pointer', borderBottom: selectedService === 'gallery' ? '3px solid blue' : '' }} 
                     onClick={() => setSelectedService('gallery')}>
                    Gallery
                </div>
            </div>

            {selectedService === "creator" && <CreatorVideoMenu key={new Date().getTime()} onCreatorClose={closeCreator} />}
            {selectedService === "gallery" && <FaceswapVideoGallery />}
        </div>
    )
}

export default FaceswapVideoMenu;