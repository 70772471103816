
import React, { useState, useEffect } from 'react';
import '../css/Features.css';
import { Features, Memberships } from '../config';
import PaypalPayment from './payment/PaypalPayment';
import T from '../language';
import { getCachedMembership } from './common/util';
import { Link } from 'react-router-dom';

function ServiceMenu({onClickHandler, onMembershpPurchaseHandler}) {
  const [membership, setMembership] = useState(0)

  useEffect(() => {
    const updateMembership = async () => {
      var v = await getCachedMembership();
      setMembership(v);
    };

    updateMembership();
  }, []);

  const getMembershipTitle = (v) => {
    if (Memberships[v] !== undefined && Memberships[v] !== null) {
      return Memberships[v];
    }
    return "";
  }

  return (
    <div className="features-container">
      {Features.map((feature, index) => {
            console.log(membership);
            if (membership >= feature.membership) {
              return (
                <Link to={"/"+feature.service.toLowerCase()}>
                  <div className="feature-card" key={index}>                    
                    <div className="feature-icon"><img src={feature.icon}/></div>
                    <div className="feature-title1">{T(feature.title)}</div>
                  </div>
                </Link>
              )
            } else {        
              return (
                <div className="feature-card-grayed-out" key={index} onClick={() => onMembershpPurchaseHandler(feature.membership)}>
                <div className="feature-icon"><img src={feature.icon}/></div>
                <div className="feature-title1">{T(feature.title)}</div>
                <div className="feature-title1">{T("" + getMembershipTitle(feature.membership))}</div>
                </div>
              )      
            }
      })}
    </div>
  )
}

const Service = () => {
  return (
      <div className="features-page">
        <ServiceMenu />
        <PaypalPayment />
      </div>
  );
};

export default Service;
